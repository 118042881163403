import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../common/hooks/hooks";
import { FormButton } from "../../common/utils/stylings";
import Alert from "@mui/material/Alert";

const ConfirmEmail = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();

  let history = useHistory();
  const Status = {
    Verifying: "Verifying",
    Failed: "Failed",
    Success: "Success",
  };
  const [status, setStatus] = useState(Status.Verifying);
  const email = useQuery().get("email");
  const token = useQuery().get("token");

  const handleConfirmEmailResend = () => {
    accountApi
      .resendEmailConfirm(email)
      .then(() => {
        handleOpenSnackbar("success", t("confirmation_email_sent"));
      })
      .catch((err) => {
        handleOpenSnackbar("error", `${err.response.data}`);
        console.log(err.response.data);
      });
  };

  useEffect(() => {
    accountApi
      .verifyEmail(token, email)
      .then(() => setStatus(Status.Success))
      .catch(() => setStatus(Status.Failed));
  }, [Status.Failed, Status.Success, token, email]);

  const getBody = () => {
    switch (status) {
      case Status.Verifying:
        return <p>{t("verifying")}</p>;
      case Status.Failed:
        return (
          <div>
            <Alert severity="error">{t("send_again")}</Alert>
            <FormButton
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                handleConfirmEmailResend();
              }}
            >
              {t("send_again")}
            </FormButton>
          </div>
        );
      case Status.Success:
        return (
          <div>
            <Alert severity="success">{t("email_verified")}</Alert>
            <FormButton variant="contained" color="primary" type="submit" onClick={() => history.push("/")}>
              {t("login")}
            </FormButton>
          </div>
        );
    }
  };

  return (
    <div className="narrow-container">
      <div className="item">
        <div className="page-title">{t("email_verification")}</div>
        <div>{getBody()}</div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
