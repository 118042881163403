import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import { requestsApi } from "../../../api/api";
import { AccountContext } from "../../../contexts/AccountContext";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";

export const EarlierToolbar = ({ setEarlier, search, setSearch, setIsLoading, setHighlightEarlier, setCurrPageEarlier, setTotalPagesEarlier, pageSize }) => {
  const { user } = useContext(AccountContext);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (fields) => {
      setIsLoading(true);
      setSearch(fields.search);
      requestsApi
        .searchEarlierByAts(1, pageSize, user.atsIcaoLocationIndicator, fields.search)
        .then((res) => {
          const pagination = JSON.parse(res.headers.pagination);
          setTotalPagesEarlier(pagination.TotalPages);
          setCurrPageEarlier(1);
          setHighlightEarlier();
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    },
  });

  const refresh = () => {
    setIsLoading(true);
    requestsApi
      .getEarlierByAts(1, pageSize, user.atsIcaoLocationIndicator)
      .then((res) => {
        const pagination = JSON.parse(res.headers.pagination);
        setTotalPagesEarlier(pagination.TotalPages);
        setEarlier(res.data);
        setCurrPageEarlier(1);
        setHighlightEarlier();
        setSearch("");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <form className="table-toolbar" onSubmit={formik.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button sx={{ textTransform: "none" }} size="small" onClick={refresh} variant="outlined" startIcon={<RefreshIcon />}>
          {t("refresh")}
        </Button>
        {search && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            <div style={{ marginLeft: 10 }}></div>
            {t("search_results")} "{search}"{" "}
            <IconButton
              size="small"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                requestsApi.getEarlierByAts(1, pageSize, user.atsIcaoLocationIndicator).then((res) => {
                  const pagination = JSON.parse(res.headers.pagination);
                  setTotalPagesEarlier(pagination.TotalPages);
                  setCurrPageEarlier(1);
                  setEarlier(res.data);
                  setSearch("");
                });
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
        )}
      </div>
      <TextField
        sx={{ width: window.innerWidth > 450 ? "40%" : "100%", marginBottom: 1, marginTop: 1 }}
        variant="outlined"
        size="small"
        id="search"
        name="search"
        value={formik.values.search}
        onChange={formik.handleChange}
        placeholder={t("search")}
        InputProps={{
          type: "search",
          startAdornment: <SearchIcon fontSize="medium" />,
        }}
      />
    </form>
  );
};
