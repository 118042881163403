import { Inside, Outdated, Outside, Unknown } from "../components/PositionStatus";
import { greenIcon, orangeIcon, redIcon, highlightGreenIcon, highlightOrangeIcon, highlightRedIcon } from "./markers";
import * as turf from "@turf/turf";

export const getLang = () => {
  const c = document.cookie.split("; ");
  const language = c.filter((item) => {
    return item.includes("LANG");
  });
  if (language.length > 0) {
    const lang = language[0].split("=")[1];
    return lang;
  } else {
    return "sv-SE";
  }
};

export const checkConsentCookie = () => {
  const c = document.cookie.split("; ");
  if (c.includes("CONSENT=true")) {
    return true;
  } else {
    return false;
  }
};

export const checkRememberMeCookie = (email) => {
  const c = document.cookie.split("; ");
  if (c.includes(`USER=${email}`)) {
    return true;
  } else {
    return false;
  }
};

export const doesRememberMeCookieExist = () => {
  const c = document.cookie.split("; ");
  const cookie = c.filter((item) => {
    return item.includes("USER");
  });
  if (cookie.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const convertToUnixTimeStamp = (date) => {
  if (date) return Math.floor(date.getTime() / 1000);
  else return Math.floor(new Date().getTime() / 1000);
};

export const convertToLocal = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  return date.toLocaleString(getLang(), {
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const removeSeconds = (d) => {
  const date = d.split(" ");
  const time = date[1].split(":");
  return `${date[0]} ${time[0]}:${time[1]}`;
};

export const getDate = (d) => {
  const date = d.split(" ");
  return `${date[0]}`;
};

export const getTime = (d) => {
  const date = d.split(" ");
  const time = date[1].split(":");
  return `${time[0]}:${time[1]}`;
};

export const partition = (array, filter) => {
  let pass = [],
    fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
};

// export const convertDMS = (lat, lng) => {
//   var latitude = toDegreesMinutesAndSeconds(lat);
//   var latitudeCardinal = lat >= 0 ? "N" : "S";

//   var longitude = toDegreesMinutesAndSeconds(lng);
//   var longitudeCardinal = lng >= 0 ? "E" : "W";

//   return latitude + " " + latitudeCardinal + "\n" + longitude + " " + longitudeCardinal;
// };

export const toDegreesMinutesAndSeconds = (coordinate) => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + "° " + minutes + "' " + seconds + '" ';
};

export const latDecToDMS = (lat) => {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";
  return latitude + " " + latitudeCardinal;
};

export const lngDecToDMS = (lng) => {
  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";
  return longitude + " " + longitudeCardinal;
};

export const timeDiff = (activatedDate) => {
  const activated = new Date(activatedDate * 1000);
  const current = new Date();
  return Math.floor((current - activated) / 60e3);
};

export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 100);
};

export const round = (number, decimalPlaces) => Number(Math.round(Number(number + "e" + decimalPlaces)) + "e" + decimalPlaces * -1);

export const colorCodeStatus = (positionStatus) => {
  switch (positionStatus) {
    case 0:
      return <Inside />;
    case 1:
      return <Unknown />;
    case 2:
      return <Outdated />;
    case 3:
      return <Outside />;
    default:
      return <Unknown />;
  }
};

export const colorCodeMarker = (status) => {
  switch (status) {
    case 0:
      return greenIcon;
    case 1:
      return orangeIcon;
    case 2:
      return orangeIcon;
    case 3:
      return redIcon;
    default:
      return orangeIcon;
  }
};

export const colorCodeMarkerHighlight = (status) => {
  switch (status) {
    case 0:
      return highlightGreenIcon;
    case 1:
      return highlightOrangeIcon;
    case 2:
      return highlightOrangeIcon;
    case 3:
      return highlightRedIcon;
    default:
      return highlightOrangeIcon;
  }
};

export const colorCodeColor = (status) => {
  switch (status) {
    case 0:
      return "#5cb85c";
    case 1:
      return "#f0ad4e";
    case 2:
      return "#f0ad4e";
    case 3:
      return "#d9534f";
    default:
      return "#f0ad4e";
  }
};

export const addSpacesToPhoneNumber = (phoneNumber) => {
  const editedPhoneNumber = phoneNumber.match(/.{1,3}/g);
  const countryCode = editedPhoneNumber[0];
  editedPhoneNumber.shift();
  const number = editedPhoneNumber.join(" ");
  return (
    <div>
      {countryCode} <span style={{ whiteSpace: "nowrap" }}>{number}</span>
    </div>
  );
};

export const wktPolygonToCoordinateArray = (wkt) => {
  // Extract the coordinates string from the WKT
  const coordinatesString = wkt.replace("POLYGON ((", "").replace("))", "").trim();

  // Split the coordinates string into individual points
  const points = coordinatesString.split(", ");

  // Convert each point into an array of [longitude, latitude]
  const coordinates = points.map((point) => {
    const [longitude, latitude] = point.split(" ").map(Number);
    return [longitude, latitude];
  });

  return coordinates;
};

export const convertMinutesToHoursMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}min`;
};

export const convertSecondToHoursMinutes = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}min`;
};
