import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCopy from "@mui/icons-material/ContentCopy";
import CallIcon from "@mui/icons-material/Call";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { convertToLocal } from "../../../common/utils/functions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { StyledPaper, LimitedBackdrop, StyledTableCell, StyledTableCellSelected } from "../../../common/utils/stylings";
import { TableHead, TableRow, TableBody, TableContainer } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

export const UpcomingTable = ({
  onHighlightUpcoming,
  highlightUpcoming,
  upcoming,
  setSelected,
  handleShow,
  setAts,
  onErase,
  isLoading,
}) => {
  const { t } = useTranslation();
  let history = useHistory();

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightUpcoming && highlightUpcoming.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              e.stopPropagation();
              onHighlightUpcoming(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.height}m</StyledTableCellSelected>
            <StyledTableCellSelected>{request.radius}m</StyledTableCellSelected>
            <StyledTableCellSelected>{convertToLocal(request.plannedDate)}</StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                <List disablePadding dense={true}>
                  <ListItem disablePadding dense={true}>
                    <ListItemButton
                      onClick={() => {
                        setSelected(request);
                        history.push("/create-request");
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding dense={true}>
                    <ListItemButton
                      onClick={() => {
                        setAts(request.ats);
                        handleShow();
                      }}
                    >
                      <ListItemIcon>
                        <CallIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("contact_ats")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding dense={true}>
                    <ListItemButton
                      onClick={() => {
                        onErase(request);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("remove")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              e.stopPropagation();
              onHighlightUpcoming(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell>{request.height}m</StyledTableCell>
            <StyledTableCell>{request.radius}m</StyledTableCell>
            <StyledTableCell>{convertToLocal(request.plannedDate)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                <List disablePadding dense={true}>
                  <ListItem disablePadding dense={true}>
                    <ListItemButton
                      onClick={() => {
                        setSelected(request);
                        history.push("/create-request");
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding dense={true}>
                    <ListItemButton
                      onClick={() => {
                        setAts(request.ats);
                        handleShow();
                      }}
                    >
                      <ListItemIcon>
                        <CallIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("contact_ats")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding dense={true}>
                    <ListItemButton
                      onClick={() => {
                        onErase(request);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("remove")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };

  return (
    <>
      {upcoming.length > 0 ? (
        <>
          <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
            <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
              <CircularProgress />
            </LimitedBackdrop>
            <Table size="small" aria-label="table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>{t("id")}</StyledTableCell>
                  <StyledTableCell>{t("address")}</StyledTableCell>
                  <StyledTableCell>{t("height")}</StyledTableCell>
                  <StyledTableCell>{t("radius")}</StyledTableCell>
                  <StyledTableCell>{t("planned_date")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {upcoming?.map((request) => (
                  <CustomRow key={request.id} request={request} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div className="mt-2">{t("missing_request")}</div>
      )}
    </>
  );
};

export const EarlierTable = ({ onHighlightEarlier, earlierRequests, setSelected, isLoading, highlightEarlier }) => {
  const { t } = useTranslation();

  let history = useHistory();

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightEarlier && highlightEarlier.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightEarlier(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.height}m</StyledTableCellSelected>
            <StyledTableCellSelected>{request.radius}m</StyledTableCellSelected>
            <StyledTableCellSelected>{convertToLocal(request.endedDate)}</StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setSelected(request);
                        history.push("/create-request");
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightEarlier(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell>{request.height}m</StyledTableCell>
            <StyledTableCell>{request.radius}m</StyledTableCell>
            <StyledTableCell>{convertToLocal(request.endedDate)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setSelected(request);
                        history.push("/create-request");
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };
  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("height")}</StyledTableCell>
            <StyledTableCell>{t("radius")}</StyledTableCell>
            <StyledTableCell>{t("ended_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {earlierRequests?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ErasedTable = ({ onHighlightErased, erasedRequests, setSelected, isLoading, highlightErased }) => {
  const { t } = useTranslation();

  let history = useHistory();

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightErased && highlightErased.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightErased(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.height}m</StyledTableCellSelected>
            <StyledTableCellSelected>{request.radius}m</StyledTableCellSelected>
            <StyledTableCellSelected>{convertToLocal(request.plannedDate)}</StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                <List dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setSelected(request);
                        history.push("/create-request");
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightErased(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell>{request.height}m</StyledTableCell>
            <StyledTableCell>{request.radius}m</StyledTableCell>
            <StyledTableCell>{convertToLocal(request.plannedDate)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                <List dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setSelected(request);
                        history.push("/create-request");
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };

  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("height")}</StyledTableCell>
            <StyledTableCell>{t("radius")}</StyledTableCell>
            <StyledTableCell>{t("planned_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {erasedRequests?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
