import { useState } from "react";
import { TableHead, TableRow, TableBody, TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Sms from "@mui/icons-material/Sms";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import CommentIcon from "@mui/icons-material/Comment";
import PrintIcon from "@mui/icons-material/Print";
import { convertToLocal, timeDiff, addSpacesToPhoneNumber } from "../../../common/utils/functions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ContentCopy from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledPaper, LimitedBackdrop, StyledTableCell, StyledTableCellSelected } from "../../../common/utils/stylings";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { METER_TO_FEET_CONVERSION_RATE } from "../../../common/utils/constants";
import { colorCodeStatus } from "../../../common/utils/functions";
import { smsApi } from "../../../api/api";

export const ActiveTable = ({
  handleShowComments,
  active,
  onHighlightActive,
  highlightActive,
  endRequest,
  setSelectedRequest,
  handleShowRequestInfo,
  setSelected,
  history,
  isLoading,
  user,
  printAida,
}) => {
  const { t } = useTranslation();

  const cancel = (request) => {
    if (window.confirm(t("confirm_abort"))) {
      smsApi
        .sendOne(request.phoneNumber, request.displayName, user.atsPhoneNumber)
        .then((res) => {})
        .catch((err) => {});
    }
  };

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightActive && highlightActive.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightActive(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}{" "}
            </StyledTableCellSelected>
            <StyledTableCellSelected>{colorCodeStatus(request.positionStatus)}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.phoneNumber ? <>{addSpacesToPhoneNumber(request.phoneNumber)}</> : "-"}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected style={{ color: request.height > 120 ? "orange" : "black" }}>
              {request.height}m ({Math.ceil(request.height * METER_TO_FEET_CONVERSION_RATE)} {t("feet")})
            </StyledTableCellSelected>
            <StyledTableCellSelected>{timeDiff(request.activatedDate)}</StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        endRequest(request);
                      }}
                    >
                      <ListItemIcon>
                        <StopCircleIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("end")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        cancel(request);
                      }}
                    >
                      <ListItemIcon>
                        <Sms fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("cancel_immediately")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightActive(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCell>
            <StyledTableCell> {colorCodeStatus(request.positionStatus)}</StyledTableCell>
            <StyledTableCell>{request.phoneNumber ? <>{addSpacesToPhoneNumber(request.phoneNumber)}</> : "-"}</StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell style={{ color: request.height > 120 ? "orange" : "black" }}>
              {request.height}m ({Math.ceil(request.height * METER_TO_FEET_CONVERSION_RATE)} {t("feet")})
            </StyledTableCell>
            <StyledTableCell>{timeDiff(request.activatedDate)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        endRequest(request);
                      }}
                    >
                      <ListItemIcon>
                        <StopCircleIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("end")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        cancel(request);
                      }}
                    >
                      <ListItemIcon>
                        <Sms fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("cancel_immediately")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };

  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("pilot")}</StyledTableCell>
            <StyledTableCell>{t("pilot_position")}</StyledTableCell>
            <StyledTableCell>{t("phone")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("height")}</StyledTableCell>
            <StyledTableCell>{t("time_active")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {active?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const UpcomingTable = ({
  handleShowComments,
  upcoming,
  onHighlightUpcoming,
  highlightUpcoming,
  activateRequest,
  setSelectedRequest,
  handleShowRequestInfo,
  setSelected,
  history,
  isLoading,
  onErase,
  setHighlightUpcoming,
  printAida,
}) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightUpcoming && highlightUpcoming.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightUpcoming(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCellSelected>
            <StyledTableCellSelected>{colorCodeStatus(request.positionStatus)}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.phoneNumber ? <>{addSpacesToPhoneNumber(request.phoneNumber)}</> : "-"}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected style={{ color: request.height > 120 ? "orange" : "black" }}>
              {request.height}m ({Math.ceil(request.height * METER_TO_FEET_CONVERSION_RATE)} {t("feet")})
            </StyledTableCellSelected>
            <StyledTableCellSelected>{convertToLocal(request.plannedDate)}</StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        activateRequest(request);
                        setHighlightUpcoming();
                      }}
                    >
                      <ListItemIcon>
                        <PlayCircleFilledIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("activate")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onErase(request);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("remove")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightUpcoming(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCell>
            <StyledTableCell> {colorCodeStatus(request.positionStatus)}</StyledTableCell>
            <StyledTableCell>{request.phoneNumber ? <>{addSpacesToPhoneNumber(request.phoneNumber)}</> : "-"}</StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell style={{ color: request.height > 120 ? "orange" : "black" }}>
              {request.height}m ({Math.ceil(request.height * METER_TO_FEET_CONVERSION_RATE)} {t("feet")})
            </StyledTableCell>
            <StyledTableCell>{convertToLocal(request.plannedDate)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        activateRequest(request);
                      }}
                    >
                      <ListItemIcon>
                        <PlayCircleFilledIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("activate")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onErase(request);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("remove")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };

  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("pilot")}</StyledTableCell>
            <StyledTableCell>{t("pilot_position")}</StyledTableCell>
            <StyledTableCell>{t("phone")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("height")}</StyledTableCell>
            <StyledTableCell>{t("planned_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {upcoming?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const EarlierTable = ({
  handleShowComments,
  earlier,
  onHighlightEarlier,
  highlightEarlier,
  setSelectedRequest,
  handleShowRequestInfo,
  setSelected,
  history,
  onPostCopied,
  isLoading,
  handleShowRequestEdit,
  printAida,
}) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightEarlier && highlightEarlier.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightEarlier(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.flightTime}</StyledTableCellSelected>
            <StyledTableCellSelected>
              {convertToLocal(request.activatedDate)} / {convertToLocal(request.endedDate)}
            </StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onPostCopied(request);
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestEdit();
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("edit_start_or_end_datetime")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightEarlier(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell>{request.flightTime}</StyledTableCell>
            <StyledTableCell>
              {convertToLocal(request.activatedDate)} / {convertToLocal(request.endedDate)}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onPostCopied(request);
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestEdit();
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("edit_start_or_end_datetime")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };

  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("pilot")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("time_active")}</StyledTableCell>
            <StyledTableCell>{t("end_datetime")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {earlier?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ErasedTable = ({
  handleShowComments,
  erased,
  onHighlightErased,
  highlightErased,
  onPostCopied,
  setSelectedRequest,
  handleShowRequestInfo,
  setSelected,
  history,
  isLoading,
  printAida,
}) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    const [open, setOpen] = useState(false);
    if (highlightErased && highlightErased.id === request.id) {
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightErased(e, request);
            }}
          >
            <StyledTableCellSelected>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.id}</StyledTableCellSelected>
            <StyledTableCellSelected>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCellSelected>
            <StyledTableCellSelected>{request.phoneNumber ? <>{addSpacesToPhoneNumber(request.phoneNumber)}</> : "-"}</StyledTableCellSelected>
            <StyledTableCellSelected>{request.address}</StyledTableCellSelected>
            <StyledTableCellSelected>{convertToLocal(request.plannedDate)}</StyledTableCellSelected>
          </TableRow>
          <TableRow>
            <StyledTableCellSelected style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onPostCopied(request);
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            </StyledTableCellSelected>
          </TableRow>
        </>
      );
    } else
      return (
        <>
          <TableRow
            hover
            key={request.id}
            onClick={(e) => {
              onHighlightErased(e, request);
            }}
          >
            <StyledTableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>{request.id}</StyledTableCell>
            <StyledTableCell>
              {request.displayName} {request.comments?.length > 0 ? <>*</> : <></>}
              {request.prioUser && <div style={{ color: "#135DD8" }}>{t("prio_user")} </div>}
            </StyledTableCell>
            <StyledTableCell>{request.phoneNumber ? <>{addSpacesToPhoneNumber(request.phoneNumber)}</> : "-"}</StyledTableCell>
            <StyledTableCell>{request.address}</StyledTableCell>
            <StyledTableCell>{convertToLocal(request.plannedDate)}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto">
                <List style={{ maxHeight: "100%", overflow: "auto" }} dense={true} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onPostCopied(request);
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("copy")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowRequestInfo();
                      }}
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("more_info")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(request);
                        history.push("pilot-history");
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("history")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRequest(request);
                        handleShowComments();
                      }}
                    >
                      <ListItemIcon>
                        <CommentIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("comments")} />
                    </ListItemButton>
                  </ListItem>
                  {/* <ListItem disablePadding>
                    <ListItemButton
                      onClick={(e) => {
                        printAida(request);
                      }}
                    >
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={t("print")} />
                    </ListItemButton>
                  </ListItem> */}
                </List>
              </Collapse>
            </StyledTableCell>
          </TableRow>
        </>
      );
  };

  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("pilot")}</StyledTableCell>
            <StyledTableCell>{t("phone")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("planned_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {erased?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const MapLayers = ({ zones, onHighlightStandardRequest, highlightStandardRequest, activateStandardRequest, endStandardRequest, standard, isLoading }) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    if (highlightStandardRequest && highlightStandardRequest.id === request.id) {
      return (
        <TableRow
          hover
          key={request.id}
          onClick={(e) => {
            onHighlightStandardRequest(request);
          }}
        >
          <StyledTableCellSelected>{request.name}</StyledTableCellSelected>
          <StyledTableCellSelected align="right">
            {zones ? (
              <div key="1">
                {zones.find((zone) => zone.id === request.id) ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      endStandardRequest(request);
                    }}
                  >
                    <StopCircleIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      activateStandardRequest(request);
                    }}
                  >
                    <PlayCircleFilledIcon />
                  </IconButton>
                )}
              </div>
            ) : (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  activateStandardRequest(request);
                }}
              >
                <PlayCircleFilledIcon />
              </IconButton>
            )}
          </StyledTableCellSelected>
        </TableRow>
      );
    }

    return (
      <TableRow
        hover
        key={request.id}
        onClick={(e) => {
          onHighlightStandardRequest(request);
        }}
      >
        <StyledTableCell>{request.name}</StyledTableCell>
        <StyledTableCell align="right">
          {zones ? (
            <div key="1">
              {zones.find((zone) => zone.id === request.id) ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    endStandardRequest(request);
                  }}
                >
                  <StopCircleIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    activateStandardRequest(request);
                  }}
                >
                  <PlayCircleFilledIcon />
                </IconButton>
              )}
            </div>
          ) : (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                activateStandardRequest(request);
              }}
            >
              <PlayCircleFilledIcon />
            </IconButton>
          )}
        </StyledTableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer sx={{ marginBottom: 1 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t("maplayers")}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {standard?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
