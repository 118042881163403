import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import ChangeEmail from "../pages/account/ChangeEmail";
import CreateAtsAccount from "../pages/account/CreateAtsAccount";
import ChangePhoneNumber from "../pages/account/ChangePhoneNumber";
import ChangeEmailRequest from "../pages/account/ChangeEmailRequest";
import ChangeEmailVerificationSent from "../pages/account/ChangeEmailVerificationSent";
import ChangePasswordLoggedIn from "../pages/account/ChangePasswordLoggedIn";
import Profile from "../pages/account/Profile";
import Ats from "../pages/ats/Ats";
import CreateAts from "../pages/ats/CreateAts";
import NoAts from "../pages/ats/NoAts";
import PilotHistory from "../pages/ats/PilotHistory";
import CreateRequest from "../pages/pilot/CreateRequest";
import Main from "../pages/other/Main";
import Unauthorized from "../pages/other/Unauthorized";
import NotFound from "../pages/other/NotFound";
import Admin from "../pages/admin/Admin";
import CreateAdmin from "../pages/admin/CreateAdmin";

import Airspace from "../pages/admin/Airspace";
import RestrictedZones from "../pages/restrictedzones/RestrictedZones";
import CreateRestrictedZone from "../pages/restrictedzones/CreateRestrictedZone";
import EditRestrictedZone from "../pages/restrictedzones/EditRestrictedZone";
import StandardRequests from "../pages/standardrequests/StandardRequests";
import CreateStandardRequest from "../pages/standardrequests/CreateStandardRequest";
import EditStandardRequest from "../pages/standardrequests/EditStandardRequest";
import Pilot from "../pages/pilot/Pilot";

export const LoggedIn = ({ handleOpenSnackbar, lng, setLng }) => {
  const [selected, setSelected] = useState();

  return (
    <>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/login" component={Main} />
        <Route exact path="/create-ats-account">
          <CreateAtsAccount handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/change-password">
          <ChangePasswordLoggedIn handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/change-phone-number">
          <ChangePhoneNumber handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/change-email-request">
          <ChangeEmailRequest handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/change-email-verification-sent">
          <ChangeEmailVerificationSent handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/change-email">
          <ChangeEmail handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/profile">
          <Profile lng={lng} setLng={setLng} />
        </Route>
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/no-ats" component={NoAts} />
        <Route exact path="/pilot">
          <Pilot setSelected={setSelected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/create-request">
          <CreateRequest selected={selected} setSelected={setSelected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/ats">
          <Ats setSelected={setSelected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/pilot-history">
          <PilotHistory selected={selected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/admin">
          <Admin handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/create-admin">
          <CreateAdmin handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/restricted-zones">
          <RestrictedZones setSelected={setSelected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/standard-requests">
          <StandardRequests setSelected={setSelected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/edit-restricted-zone">
          <EditRestrictedZone selected={selected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/edit-standard-request">
          <EditStandardRequest selected={selected} handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/airspace">
          <Airspace handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/create-restricted-zone">
          <CreateRestrictedZone handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/create-standard-request">
          <CreateStandardRequest handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route exact path="/create-ats">
          <CreateAts handleOpenSnackbar={handleOpenSnackbar} />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
