import * as Yup from "yup";
import { BackButton } from "../../common/components/BackButton";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { FormButton } from "../../common/utils/stylings";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import Paper from "@mui/material/Paper";

const ForgotPassword = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email_invalid")).required(t("email_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      accountApi
        .resetPasswordRequest(fields.email)
        .then(() => {
          handleOpenSnackbar("success", t("recovery_email"));
        })
        .catch((err) => {
          handleOpenSnackbar("error", `${err.response.data}`);
          console.log(err.response.data);
        });
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <Paper sx={{ padding: 1 }} elevation={3}>
            <BackButtonWithTitle title={t("forgot_your_password")} />
            <div className="item">
              <div style={{ marginBottom: 5, fontSize: 16 }}>{t("forgot_password_info")}</div>
              <div>
                <TextField
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  label={t("email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <FormButton type="submit" variant={"contained"}>
                {t("send")}
              </FormButton>
            </div>
          </Paper>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
