import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { MapContainer, TileLayer, Circle, useMapEvent, Polygon, Marker, useMap } from "react-leaflet";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Alert from "@mui/material/Alert";
import { BackButton } from "../../common/components/BackButton";
import { redIcon } from "../../common/utils/markers";
import { convertToUnixTimeStamp, scrollToTop } from "../../common/utils/functions";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { restrictedZonesApi, atsApi, requestsApi } from "../../api/api";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import NativeSelect from "@mui/material/NativeSelect";
import { useFormik } from "formik";
import { FormButton } from "../../common/utils/stylings";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";

const CreateRequest = ({ selected, setSelected, handleOpenSnackbar }) => {
  const { t } = useTranslation();

  const { user } = useContext(AccountContext);

  const [position, setPosition] = useState();
  const [ats, setAts] = useState();
  const [center, setCenter] = useState();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [plannedDate, setPlannedDate] = useState(new Date());
  const [restrictedZones, setRestrictedZones] = useState([]);
  const [atses, setAtses] = useState([{}]);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  let history = useHistory();

  const validationSchema = Yup.object().shape({
    ctr: Yup.string().required(t("ats_required")),
    latitude: Yup.string().test("Obligatoriskt", t("location_required"), (value) => {
      if (lat) return true;
      else return false;
    }),
    longitude: Yup.string().test("Obligatoriskt", t("location_required"), (value) => {
      if (lng) return true;
      else return false;
    }),
    height: Yup.string()
      .min(2, t("height_min"))
      .matches(/^[0-9]*$/, t("numbers_only"))
      .required(t("height_required")),
    radius: Yup.string()
      .min(2, t("radius_min"))
      .matches(/^[0-9]*$/, t("numbers_only"))
      .required(t("radius_required")),
    estimatedFlightTime: Yup.string().matches(/^[0-9]*$/, t("numbers_only")),
    address: Yup.string().required(t("address_required")),
  });

  const formik = useFormik({
    initialValues: {
      ctr: selected ? selected.ats : "",
      latitude: lat,
      longitude: lng,
      height: "",
      radius: 300,
      address: "",
      estimatedFlightTime: "",
      toggle: false,
      error: null,
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      setSubmitting(true);
      const req = {
        ats: ats,
        address: fields.address,
        latitude: lat,
        longitude: lng,
        height: fields.height,
        radius: fields.radius,
        estimatedFlightTime: fields.estimatedFlightTime,
        plannedDate: convertToUnixTimeStamp(plannedDate),
      };

      requestsApi
        .postRequest(req)
        .then((res) => {
          setSelected();
          history.goBack();
          setSubmitting(false);
        })
        .catch((err) => {
          if (err) {
            handleOpenSnackbar("error", err.response?.data);
          } else {
            handleOpenSnackbar("error", t("generic_error"));
          }
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Pilot")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (selected) {
      try {
        formik.setFieldValue("height", selected.height);
        formik.setFieldValue("radius", selected.radius);
        formik.setFieldValue("address", selected.address);
        formik.setFieldValue("ctr", selected.ats.name);
        formik.setFieldValue("estimatedFlightTime", selected.estimatedFlightTime);

        setPosition([selected.lat, selected.lng]);
        setCenter([selected.lat, selected.lng]);
        setLat(selected.lat);
        setLng(selected.lng);
        setAts(selected.ats);

        restrictedZonesApi
          .getRestrictedByAts(selected.ats.id)
          .then((res) => {
            if (res.data.length > 0) {
              setRestrictedZones(res.data);
            }
          })
          .catch((err) => {
            // console.log(err);
          });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        history.goBack();
      }
    } else if (user.ats) {
      try {
        setAts(user.ats);
        restrictedZonesApi
          .getRestrictedByAts(user.atsId)
          .then((res) => {
            if (res.data.length > 0) {
              setRestrictedZones(res.data);
            }
          })
          .catch((err) => {
            // console.log(err);
          });
        setCenter([user.atsLat, user.atsLng]);
        if (user.ats) {
          formik.setFieldValue("ctr", user.atsName);
        } else {
          formik.setFieldValue("ctr", "");
        }
        setLat("");
        setLng("");
        setIsLoading(false);
      } catch (err) {
        // console.log(err);
        setIsLoading(false);
      }
    } else {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setCenter([pos.coords.latitude, pos.coords.longitude]);
        },
        (err) => {},
        [
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        ]
      );
      try {
        setIsLoading(false);
      } catch (err) {
        // console.log(err);
        setIsLoading(false);
      }
    }
    retrieveCreateReqData();
    return () => {
      setSelected();
    };
  }, []);

  const retrieveCreateReqData = () => {
    setIsPageLoading(true);
    if (ats) {
      Promise.all([atsApi.getAtsesProduction(), restrictedZonesApi.getRestrictedByAts(ats.id)])
        .then(([atses, restricted]) => {
          setAtses(atses.data);
          if (restricted.data.length > 0) {
            setRestrictedZones(restricted.data);
          }
          setIsPageLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsPageLoading(false);
        });
    } else {
      atsApi
        .getAtsesProduction()
        .then((res) => {
          setAtses(res.data);
          setIsPageLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsPageLoading(false);
        });
    }
  };

  function MapOnClick() {
    const map = useMapEvent("click", (e) => {
      setPosition(e.latlng);
      setLat(e.latlng.lat);
      setLng(e.latlng.lng);
      setCenter(e.latlng);
    });
    return null;
  }

  function SetViewOnClick({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  const handleChangeAts = (event) => {
    formik.setFieldValue("ctr", event.target.value);
    if (!event.target.value) return;
    const selectedAts = atses.find((ats) => ats.name === event.target.value);
    if (selectedAts) {
      setAts(selectedAts);
      restrictedZonesApi
        .getRestrictedByAts(selectedAts.id)
        .then((res) => {
          setRestrictedZones(res.data);
        })
        .catch((err) => {
          // console.log(err);
        });
      setCenter([selectedAts.lat, selectedAts.lng]);
    }
  };

  return (
    <div className="standard-container">
      <Paper sx={{ padding: 2 }} elevation={3}>
        <BackButtonWithTitle title={t("create_new_request")} />
        <div className="item">
          {isLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          )}
          <div>
            <form autoComplete="chrome-off" onSubmit={formik.handleSubmit}>
              <FormControl size="large" variant="standard" sx={{ marginTop: 1, marginBottom: 2 }}>
                <InputLabel shrink={true} id="ats-select">
                  {t("ats")}
                </InputLabel>
                <NativeSelect
                  value={formik.values.ctr}
                  inputProps={{
                    name: "ats",
                    id: "uncontrolled-native",
                  }}
                  id="ats-select"
                  onChange={handleChangeAts}
                >
                  <option key="null" value="" />
                  {atses?.map((ats) => {
                    return (
                      <option key={ats.id} value={ats.name}>
                        {ats.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              <div className="map-container">
                {center && (
                  <>
                    <Map restrictedZones={restrictedZones} position={position} radius={formik.values.radius} MapOnClick={MapOnClick} SetViewOnClick={SetViewOnClick} center={center} ats={ats} />
                    <div>
                      {window.innerWidth < 1000 && (
                        <Button
                          startIcon={<MyLocationIcon />}
                          disabled={isLoading}
                          variant="contained"
                          color="info"
                          size="small"
                          sx={{ marginTop: 1, marginBottom: 1, textTransform: "none" }}
                          title={t("mark_my_position")}
                          onClick={() => {
                            try {
                              navigator.geolocation.getCurrentPosition(
                                (pos) => {
                                  setCenter([pos.coords.latitude, pos.coords.longitude]);
                                },
                                (err) => {
                                  // console.log(err);
                                  handleOpenSnackbar("error", t("location_not_found"));
                                },
                                [
                                  {
                                    enableHighAccuracy: true,
                                    timeout: 5000,
                                    maximumAge: 0,
                                  },
                                ]
                              );
                            } catch (err) {
                              // console.log(t("location_not_found"));
                            }
                          }}
                        >
                          {t("my_position")}
                        </Button>
                      )}
                      <Alert severity="warning" sx={{ marginBottom: 1, marginTop: 1 }}>
                        {t("mark_position_info")}
                      </Alert>
                    </div>
                  </>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  focused
                  disabled
                  style={{ width: "49%" }}
                  variant="standard"
                  fullWidth
                  id="latitude"
                  type="number"
                  name="latitude"
                  label={t("latitude")}
                  value={lat}
                  onChange={(e) => {
                    setLat(e.target.value);
                  }}
                  error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                  helperText={formik.touched.latitude && formik.errors.latitude}
                />
                <TextField
                  disabled
                  style={{ width: "49%" }}
                  variant="standard"
                  fullWidth
                  id="longitude"
                  type="number"
                  name="longitude"
                  label={t("longitude")}
                  value={lng}
                  onChange={(e) => {
                    setLng(e.target.value);
                  }}
                  error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                  helperText={formik.touched.longitude && formik.errors.longitude}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled={!formik.values.ctr}
                  variant="standard"
                  style={{ width: "49%" }}
                  fullWidth
                  id="height"
                  name="height"
                  type="number"
                  label={t("height_with_unit")}
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={formik.touched.height && formik.errors.height}
                />
                <TextField
                  disabled={!formik.values.ctr}
                  variant="standard"
                  style={{ width: "49%" }}
                  fullWidth
                  id="radius"
                  name="radius"
                  type="number"
                  label={t("radius_with_unit")}
                  value={formik.values.radius}
                  onChange={formik.handleChange}
                  error={formik.touched.radius && Boolean(formik.errors.radius)}
                  helperText={formik.touched.radius && formik.errors.radius}
                />
              </div>
              <div>
                {Number(formik.values.height) > 120 && (
                  <Alert sx={{ marginTop: 1, marginBottom: 1 }} severity="error">
                    {t("height_warning")}
                  </Alert>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled={!formik.values.ctr}
                  variant="standard"
                  style={{ width: "49%" }}
                  fullWidth
                  id="address"
                  name="address"
                  label={t("address")}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    disabled={!formik.values.ctr}
                    inputFormat="yyyy-MM-dd HH:mm"
                    id="date"
                    name="date"
                    label={t("date_approximate_time")}
                    ampm={false}
                    value={plannedDate}
                    onChange={(date) => {
                      setPlannedDate(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={!formik.values.ctr}
                        style={{ width: "49%" }}
                        fullWidth
                        {...params}
                        variant="standard"
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <TextField
                disabled={!formik.values.ctr}
                variant="standard"
                style={{ width: "49%" }}
                fullWidth
                id="estimatedFlightTime"
                name="estimatedFlightTime"
                type="number"
                label={t("approximate_flight_time")}
                value={formik.values.estimatedFlightTime}
                onChange={formik.handleChange}
                error={formik.touched.estimatedFlightTime && Boolean(formik.errors.estimatedFlightTime)}
                helperText={formik.touched.estimatedFlightTime && formik.errors.estimatedFlightTime}
              />
              <div style={{ display: "flex", marginTop: 10 }}>
                <Checkbox id="toggle" name="toggle" checked={formik.values.toggle} onChange={formik.handleChange} />
                <div>
                  <ul>
                    <b>{t("hereby_confirm")}</b>
                    <li>{t("hereby_confirm2")}</li>
                    <li>{t("hereby_confirm3", { phoneNumber: user.phoneNumber })}</li>
                    {ats?.disclaimer && <li>-{ats.disclaimer}</li>}
                  </ul>
                </div>
              </div>
              <FormButton isLoading={submitting} disabled={!formik.values.toggle || !formik.values.ctr || submitting} type="submit" variant="contained">
                {t("create_request")}
              </FormButton>
            </form>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CreateRequest;

const Map = ({ restrictedZones, position, radius, MapOnClick, SetViewOnClick, center, ats }) => {
  return (
    <MapContainer id="pilot-map" center={center} zoom={7.5} scrollWheelZoom={false}>
      <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
      <MapOnClick />
      <SetViewOnClick center={center} />
      {position && (
        <Circle center={position} radius={radius} fillColor="#00008b" fillOpacity={0.5} color="#00008b" opacity={0.2}>
          <Marker icon={redIcon} position={position} />
        </Circle>
      )}
      {restrictedZones?.map((zone) => {
        if (zone.area !== null) return <Polygon key={zone.id} pathOptions={{ color: "red" }} positions={zone.area} opacity={0} />;
        else return <Circle key={zone.id} center={[zone.lat, zone.lng]} radius={zone.radius} fillColor="#FF0000" color="#FF0000" opacity={0.2}></Circle>;
      })}
      {ats && (
        <>
          <Polygon key={ats.id} pathOptions={{ color: "red" }} positions={ats.airspace} fillOpacity={0} />
        </>
      )}
    </MapContainer>
  );
};
