import L from "leaflet";
import green from "../../assets/green.svg";
import highlightGreen from "../../assets/highlightGreen.svg";
import blue from "../../assets/blue.svg";
import red from "../../assets/red.svg";
import highlightRed from "../../assets/highlightRed.svg";
import purple from "../../assets/purple.svg";
import orange from "../../assets/orange.svg";
import highlightOrange from "../../assets/highlightOrange.svg";
import grey from "../../assets/grey.svg";
import drone from "../../assets/drone.png";

export const greenIcon = L.icon({
  iconUrl: green,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const highlightGreenIcon = L.icon({
  iconUrl: highlightGreen,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const blueIcon = L.icon({
  iconUrl: blue,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const redIcon = L.icon({
  iconUrl: red,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const highlightRedIcon = L.icon({
  iconUrl: highlightRed,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const purpleIcon = L.icon({
  iconUrl: purple,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const orangeIcon = L.icon({
  iconUrl: orange,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const highlightOrangeIcon = L.icon({
  iconUrl: highlightOrange,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const greyIcon = L.icon({
  iconUrl: grey,
  iconSize: [30, 40], // size of the icon
  iconAnchor: [15, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});

export const droneIcon = L.icon({
  iconUrl: drone,
  iconSize: [22, 22], // size of the icon
  iconAnchor: [11, 11], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
});
