import React, { useState, useContext } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import "./App.css";
import { Footer } from "./common/components/Footer";
import { Navigation } from "./navigation";
import { checkConsentCookie, round } from "./common/utils/functions";
import { useEffect } from "react";
import { Header } from "./common/components/Header";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { AccountContext } from "./contexts/AccountContext";
import { useTranslation } from "react-i18next";

const App = () => {
  const [hasCookie, setHasCookie] = useState(checkConsentCookie());
  const [openCookiebar, setOpenCookiebar] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("info");
  const { t, i18n } = useTranslation();
  const { setUser } = useContext(AccountContext);
  const [lng, setLng] = useState("");

  let history = useHistory();

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use((config) => {
    const jwt = localStorage.getItem("jwt");
    if (jwt !== null) {
      const userObj = JSON.parse(jwt);
      const token = userObj.token;

      const tokenExpires = jwt_decode(token).exp;
      const expires = new Date(tokenExpires * 1000);
      const now = new Date();
      const timeLeft = (expires - now) / 1000;
      if (timeLeft <= 300) {
        window.alert(t("session_expired"));
        localStorage.clear();
        setUser(null);
        history.push("/");
      }
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      return config;
    }
  });

  useEffect(() => {
    setOpenCookiebar(!hasCookie);
  }, []);

  useEffect(() => {
    const c = document.cookie.split("; ");
    const language = c.filter((item) => {
      return item.includes("LANG");
    });
    if (language.length > 0) {
      const lang = language[0].split("=")[1];
      i18n.changeLanguage(lang);
      setLng(lang);
    } else {
      i18n.changeLanguage("sv-SE");
      setLng("sv-SE");
    }
  }, []);

  const handleCloseCookiebar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenCookiebar(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (severity, message) => {
    setSnackbarType(severity);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  return (
    <div className="App">
      <div className="content">
        {/* <AccountContextProvider handleOpenSnackbar={handleOpenSnackbar}> */}
        <Header lng={lng} setLng={setLng} />
        <Navigation handleOpenSnackbar={handleOpenSnackbar} lng={lng} setLng={setLng} />
        {/* </AccountContextProvider> */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openCookiebar}
          onClose={handleCloseCookiebar}
          message={t("cookie_banner")}
          action={
            <Button
              color="primary"
              size="small"
              onClick={(event, reason) => {
                const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                document.cookie = `CONSENT=true; expires=${expires}`;
                setHasCookie(true);
                handleCloseCookiebar(event, reason);
              }}
              style={{ color: "yellow" }}
            >
              {t("ok")}
            </Button>
          }
        />
        <Footer />
      </div>
    </div>
  );
};

export default App;
