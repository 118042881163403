import React from "react";
import { Switch, Route } from "react-router-dom";
import ChangeEmailVerificationSent from "../pages/account/ChangeEmailVerificationSent";
import Login from "../pages/account/Login";
import Register from "../pages/account/Register";
import ForgotPassword from "../pages/account/ForgotPassword";
import RegisterSuccess from "../pages/account/RegisterSuccess";
import ConfirmEmail from "../pages/account/ConfirmEmail";
import ChangePassword from "../pages/account/ChangePassword";
import ChangeEmail from "../pages/account/ChangeEmail";
import NotFound from "../pages/other/NotFound";
import Gdpr from "../pages/other/Gdpr";

export const LoggedOut = ({ handleOpenSnackbar }) => {
  return (
    <Switch>
      <Route exact path="/">
        <Login handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/login">
        <Login handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/register">
        <Register handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/register-success">
        <RegisterSuccess handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/verify-email">
        <ConfirmEmail handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/reset-password">
        <ChangePassword handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/change-email">
        <ChangeEmail handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/change-email-verification-sent">
        <ChangeEmailVerificationSent handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route exact path="/gdpr">
        <Gdpr handleOpenSnackbar={handleOpenSnackbar} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};
