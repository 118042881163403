import React, { useState, useEffect } from "react";
import { convertToLocal, latDecToDMS, lngDecToDMS, round, addSpacesToPhoneNumber, convertMinutesToHoursMinutes, convertSecondToHoursMinutes } from "../../../common/utils/functions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { METER_TO_FEET_CONVERSION_RATE } from "../../../common/utils/constants";
import { elevationApi, touchedSectorsApi } from "../../../api/api";

export const InfoModal = ({ atsId, atsBetaFeatures, show, handleClose, isLoading, selectedRequest }) => {
  const [elevation, setElevation] = useState(0);
  const [requestHeightSeaLevel, setRequestHeightSeaLevel] = useState(0);
  const [touchedSectors, setTouchedSectors] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedRequest) {
      setElevation(0);
      setRequestHeightSeaLevel(selectedRequest.height);

      elevationApi.get(selectedRequest.id).then((res) => {
        setElevation(res);
        setRequestHeightSeaLevel(res + selectedRequest.height);
      });

      touchedSectorsApi.get(atsId, selectedRequest.id).then((res) => {
        setTouchedSectors(res);
      });
    }
  }, [selectedRequest, atsId]);

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        {selectedRequest && (
          <>
            <ul>
              <div>
                <b>{t("created_by")}:</b>
                <li>
                  {t("name")}: {selectedRequest.displayName}
                </li>
                <li>
                  {t("phone")}: {selectedRequest.phoneNumber ? <>{addSpacesToPhoneNumber(selectedRequest.phoneNumber)}</> : "-"}
                </li>
                <li>
                  {t("email")}: {selectedRequest.email && selectedRequest.email.includes("@pilot.com") ? t("not_specified") : selectedRequest.email}
                </li>
              </div>
              <div>
                <br />
                <b>{t("request")}:</b>
                <table>
                  <tbody>
                    <tr className="info-row">
                      <td>{t("id")}</td>
                      <td>{selectedRequest.id}</td>
                    </tr>
                    <tr className="info-row">
                      <td>{t("address")}</td>
                      <td>{selectedRequest.address}</td>
                    </tr>
                    <tr className="info-row">
                      <td> {t("latitude")}</td>
                      <td>
                        <b>{t("decimal")}</b> {round(selectedRequest.lat, 6)} <b>{t("dms")}</b> {latDecToDMS(selectedRequest.lat)}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td>{t("longitude")}</td>
                      <td>
                        <b>{t("decimal")}</b> {round(selectedRequest.lng, 6)} <b>{t("dms")}</b> {lngDecToDMS(selectedRequest.lng)}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td>{t("radius_no_unit")}</td>
                      <td>{selectedRequest.radius}m</td>
                    </tr>
                    <tr className="info-row">
                      <td>{t("height_no_unit")}</td>
                      <td>
                        {selectedRequest.height}m ({Math.ceil(selectedRequest.height * METER_TO_FEET_CONVERSION_RATE)}
                        {t("feet")}) {t("above_ground")}
                      </td>
                    </tr>
                    <tr className="info-row">
                      <td>{t("planned_request_start")}</td>
                      <td>{convertToLocal(selectedRequest.plannedDate)}</td>
                    </tr>
                    {selectedRequest.estimatedFlightTime > 0 && (
                      <tr className="info-row">
                        <td>{t("approximate_flight_time")}</td>
                        <td>{convertMinutesToHoursMinutes(selectedRequest.estimatedFlightTime)}</td>
                      </tr>
                    )}
                    <tr className="info-row">
                      <td>{t("planned_request_end")}</td>
                      <td>{convertToLocal(selectedRequest.plannedDate + selectedRequest.estimatedFlightTime * 60)}</td>
                    </tr>
                    {selectedRequest.activatedDate && (
                      <tr className="info-row">
                        <td>{t("request_start")}</td>
                        <td>{convertToLocal(selectedRequest.activatedDate)}</td>
                      </tr>
                    )}
                    {selectedRequest.endedDate && (
                      <tr className="info-row">
                        <td>{t("request_end")}</td>
                        <td>{convertToLocal(selectedRequest.endedDate)}</td>
                      </tr>
                    )}
                    {selectedRequest.activatedDate && selectedRequest.endedDate && (
                      <tr className="info-row">
                        <td>{t("actual_flight_time")}</td>
                        <td>{convertSecondToHoursMinutes(selectedRequest.endedDate - selectedRequest.activatedDate)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div style={{ marginTop: 15 }} />
              </div>
            </ul>
            {atsBetaFeatures && (
              <>
                <div>
                  <br />
                  <Paper sx={{ padding: 0.4 }} elevation={5}>
                    <div style={{ color: "#D18700" }}>BETA</div>
                    <div style={{ marginTop: 10 }} />
                    <div style={{ fontWeight: "bold", fontSize: 20 }}>{t("terrain")}</div>
                    <ul>
                      <li>
                        {t("terrain")}: {elevation} {t("masl")} ({Math.ceil(elevation * METER_TO_FEET_CONVERSION_RATE)}
                        {t("feet")} {t("qnh")})
                      </li>
                      <li>
                        <b>
                          {t("terrain")} + {t("flight_altitude")}: {elevation + selectedRequest.height}m ({Math.ceil(requestHeightSeaLevel * METER_TO_FEET_CONVERSION_RATE)}
                          {t("feet")} {t("qnh")})
                        </b>
                      </li>
                    </ul>
                    <div style={{ marginTop: 10 }} />
                    <div style={{ fontWeight: "bold", fontSize: 20 }}>{t("touched_sectors")}</div>
                    <ul>
                      {touchedSectors?.map((sector) => {
                        return <li key={sector.id}>{sector.name}</li>;
                      })}
                    </ul>
                  </Paper>
                </div>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
