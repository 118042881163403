import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import Button from "@mui/material/Button";
import { AtsPhoneNumberModal } from "./components/AtsPhoneNumberModal";
import { PageSizeModal } from "./components/PageSizeModal";
import { AtsesTable, PilotTable, AtsAccountsTable, AdminTable, SettingsTable } from "./components/AdminTables";
import { accountApi, atsApi, settingsApi } from "../../api/api";
import { PilotToolbar } from "./components/PilotToolbar";
import { AdminToolbar } from "./components/AdminToolbar";
import { AtsesToolbar } from "./components/AtsesToolbar";
import Pagination from "@mui/material/Pagination";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { AtsAccountsToolbar } from "./components/AtsAccountsToolbar";
import { useTranslation } from "react-i18next";
import { AtsDisclaimerModal } from "./components/AtsDisclaimerModal";

const Admin = ({ handleOpenSnackbar }) => {
  const { user } = useContext(AccountContext);
  const [tab, setTab] = useState(0);
  const [usersTab, setUsersTab] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [atses, setAtses] = useState([]);
  const [pilots, setPilots] = useState([]);
  const [atsAccounts, setAtsAccounts] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedAts, setSelectedAts] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPilots, setSearchPilots] = useState("");
  const [searchAtsAccounts, setSearchAtsAccounts] = useState("");
  const [currPageAtsAccounts, setCurrPageAtsAccounts] = useState(1);
  const [totalPagesAtsAccounts, setTotalPagesAtsAccounts] = useState(1);
  const [searchAdmins, setSearchAdmins] = useState("");
  const [searchAtses, setSearchAtses] = useState("");
  const [currPagePilots, setCurrPagePilots] = useState(1);
  const [currPageAdmins, setCurrPageAdmins] = useState(1);
  const [currPageAtses, setCurrPageAtses] = useState(1);
  const [totalPagesPilots, setTotalPagesPilots] = useState(1);
  const [totalPagesAdmins, setTotalPagesAdmins] = useState(1);
  const [totalPagesAtses, setTotalPagesAtses] = useState(1);
  const [showPageSizeModal, setShowPageSizeModal] = useState(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const handleClosePhoneNumberModal = () => setShowPhoneNumberModal(false);
  const handleShowPhoneNumberModal = () => setShowPhoneNumberModal(true);
  const handleShowDisclaimerModal = () => setShowDisclaimerModal(true);
  const handleCloseDisclaimerModal = () => setShowDisclaimerModal(false);
  const handleClosePageSizeModal = () => setShowPageSizeModal(false);
  const handleShowPageSizeModal = () => setShowPageSizeModal(true);

  const { t } = useTranslation();

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    const getInitialData = () => {
      setIsPageLoading(true);
      accountApi
        .getUsersByRole(1, 20, "Pilot")
        .then((res) => {
          const pagination = JSON.parse(res.headers.pagination);
          setTotalPagesPilots(pagination.TotalPages);
          setPilots(res.data);
          setIsPageLoading(false);
          getRemainingData();
        })
        .catch((err) => {
          setIsPageLoading(false);
          getRemainingData();
        });
    };
    const getRemainingData = () => {
      Promise.all([atsApi.getAtses(1, 20), accountApi.getUsersByRoleWithAts(1, 20, "Ats"), accountApi.getUsersByRole(1, 20, "Admin")])
        .then(([ats, atsAccs, adms]) => {
          const paginationAdmins = JSON.parse(adms.headers.pagination);
          setTotalPagesAdmins(paginationAdmins.TotalPages);

          const paginationAtsAccounts = JSON.parse(ats.headers.pagination);
          setTotalPagesAtsAccounts(paginationAtsAccounts.TotalPages);

          const paginationAtses = JSON.parse(ats.headers.pagination);
          setTotalPagesAtses(paginationAtses.TotalPages);
          setAtses(ats.data);
          setAtsAccounts(atsAccs.data);
          setAdmins(adms.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getInitialData();

    settingsApi
      .getPageSize()
      .then((res) => {
        setPageSize(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const newPagePilots = (p) => {
    setIsLoading(true);
    setCurrPagePilots(p);
    if (!searchPilots) {
      accountApi
        .getUsersByRole(p, 20, "Pilot")
        .then((res) => {
          setPilots(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      accountApi
        .searchUsersByRole(p, 20, "Pilot", searchPilots)
        .then((res) => {
          setPilots(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageAtsAccounts = (p) => {
    setIsLoading(true);
    setCurrPageAtsAccounts(p);
    if (!searchAtsAccounts) {
      accountApi
        .getUsersByRole(p, 20, "Ats")
        .then((res) => {
          setAtsAccounts(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      accountApi
        .searchUsersByRole(p, 20, "Ats", searchAtsAccounts)
        .then((res) => {
          setAtsAccounts(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageAdmins = (p) => {
    setIsLoading(true);
    setCurrPageAdmins(p);
    if (!searchAdmins) {
      accountApi
        .getUsersByRole(p, 20, "Admin")
        .then((res) => {
          setAdmins(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      accountApi
        .searchUsersByRole(p, 20, "Admin", searchAdmins)
        .then((res) => {
          setAdmins(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const newPageAtses = (p) => {
    setIsLoading(true);
    setCurrPageAtses(p);
    if (!searchAtses) {
      atsApi
        .getAtses(p, 20)
        .then((res) => {
          setAtses(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      atsApi
        .searchAtses(p, searchAtses)
        .then((res) => {
          setAtses(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const onPrioUserChange = (user, bool) => {
    setIsLoading(true);
    accountApi.patchUser(user.id, "/prioUser", "replace", bool).then((res) => {
      accountApi
        .getUsersByRole(1, 20, "Pilot")
        .then((res) => {
          setPilots(res.data);
          if (bool) {
            handleOpenSnackbar("success", t("user_is_prio", { user: user.email }));
          } else if (!bool) {
            handleOpenSnackbar("error", t("user_is_not_prio", { user: user.email }));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const onAtsProductionChange = (ats, bool) => {
    setIsLoading(true);
    atsApi.patchAts(ats.id, "/production", "replace", bool).then((res) => {
      atsApi
        .getAtses(currPageAtses, 20)
        .then((res) => {
          setAtses(res.data);
          if (bool) {
            handleOpenSnackbar("success", t("ats_is_production", { ats: ats.name }));
          } else if (!bool) {
            handleOpenSnackbar("error", t("ats_is_not_production", { ats: ats.name }));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const onBetaFeaturesToggle = (ats, bool) => {
    setIsLoading(true);
    atsApi.patchAts(ats.id, "/betaFeatures", "replace", bool).then((res) => {
      atsApi
        .getAtses(currPageAtses, 20)
        .then((res) => {
          setAtses(res.data);
          if (bool) {
            handleOpenSnackbar("success", t("beta_on"));
          } else if (!bool) {
            handleOpenSnackbar("error", t("beta_off"));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const onEmailNotificationsToggle = (ats, bool) => {
    setIsLoading(true);
    atsApi.patchAts(ats.id, "/emailNotifications", "replace", bool).then((res) => {
      atsApi
        .getAtses(currPageAtses, 20)
        .then((res) => {
          setAtses(res.data);
          if (bool) {
            handleOpenSnackbar("success", t("email_notifications_on"));
          } else if (!bool) {
            handleOpenSnackbar("error", t("email_notifications_off"));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const onLock = (user) => {
    if (window.confirm(t("confirm_lock"))) {
      setIsLoading(true);
      accountApi.banUser(user.email).then((res) => {
        accountApi
          .getUsersByRole(1, 20, "Pilot")
          .then((res) => {
            setPilots(res.data);
            handleOpenSnackbar("success", t("account_is_locked"));
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      });
    }
  };

  const onUnlock = (user) => {
    if (window.confirm(t("confirm_unlock"))) {
      setIsLoading(true);
      accountApi.unBanUser(user.email).then((res) => {
        accountApi
          .getUsersByRole(1, 20, "Pilot")
          .then((res) => {
            setPilots(res.data);
            handleOpenSnackbar("success", t("account_is_unlocked"));
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      });
    }
  };

  // const onAdd = (selectedUser, a) => {
  //   accountApi
  //     .addUserToATS(selectedUser, a.id)
  //     .then((res) => {
  //       handleOpenSnackbar("success", `Användare ${selectedUser.email} tillagd till ATS ${a.name}`);
  //       console.log(res.data);
  //       accountApi
  //         .getUsersByRole(1, 20, "Ats")
  //         .then((res) => {
  //           setAtsAccounts(res.data);
  //         })
  //         .catch((err) => console.log(err));
  //     })
  //     .catch((err) => {
  //       handleOpenSnackbar("error", `Användare ${selectedUser.email} kunde inte läggas till i ATS ${a.name}`);
  //     });
  // };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="standard-container">
      <div className="item">
        <AtsPhoneNumberModal
          show={showPhoneNumberModal}
          handleClose={handleClosePhoneNumberModal}
          selectedAts={selectedAts}
          handleOpenSnackbar={handleOpenSnackbar}
          setAtses={setAtses}
        />
        <AtsDisclaimerModal
          show={showDisclaimerModal}
          handleClose={handleCloseDisclaimerModal}
          selectedAts={selectedAts}
          handleOpenSnackbar={handleOpenSnackbar}
          setAtses={setAtses}
        />
        <PageSizeModal
          show={showPageSizeModal}
          handleClose={handleClosePageSizeModal}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleOpenSnackbar={handleOpenSnackbar}
        />
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
          }}
          aria-label="tabs"
        >
          <Tab label={t("user_tab")} />
          <Tab label={t("ats_tab")} />
          <Tab label={t("settings_tab")} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <Button
            sx={{ marginTop: 1, marginBottom: 1 }}
            variant="contained"
            onClick={() => history.push("/create-admin")}
            disabled={isLoading}
          >
            {t("create_admin")}
          </Button>
          <Tabs
            value={usersTab}
            onChange={(e, newValue) => {
              setUsersTab(newValue);
            }}
            aria-label="tabs"
          >
            <Tab label={t("pilot_tab")} />
            <Tab label={t("ats_account_tab")} />
            <Tab label={t("admin_tab")} />
          </Tabs>
          <TabPanel value={usersTab} index={0}>
            <PilotToolbar setPilots={setPilots} search={searchPilots} setSearch={setSearchPilots} setIsLoading={setIsLoading} />
            <PilotTable
              pilots={pilots}
              onPrioUserChange={onPrioUserChange}
              onLock={onLock}
              onUnlock={onUnlock}
              user={user}
              isLoading={isLoading}
            />
            <Pagination
              sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
              shape="rounded"
              count={totalPagesPilots}
              page={currPagePilots}
              onChange={(e, newValue) => {
                newPagePilots(newValue);
              }}
            />
          </TabPanel>
          <TabPanel value={usersTab} index={1}>
            <AtsAccountsToolbar
              setAtsAccounts={setAtsAccounts}
              search={searchAtsAccounts}
              setSearch={setSearchAtsAccounts}
              setIsLoading={setIsLoading}
            />
            <AtsAccountsTable atsAccounts={atsAccounts} />{" "}
            <Pagination
              sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
              shape="rounded"
              count={totalPagesAtsAccounts}
              page={currPageAtsAccounts}
              onChange={(e, newValue) => {
                newPageAtsAccounts(newValue);
              }}
            />
          </TabPanel>
          <TabPanel value={usersTab} index={2}>
            <AdminToolbar setAdmins={setAdmins} search={searchAdmins} setSearch={setSearchAdmins} setIsLoading={setIsLoading} />
            <AdminTable admins={admins} isLoading={isLoading} />{" "}
            <Pagination
              sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
              shape="rounded"
              count={totalPagesAdmins}
              page={currPageAdmins}
              onChange={(e, newValue) => {
                newPageAdmins(newValue);
              }}
            />
          </TabPanel>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Button sx={{ marginTop: 1, marginBottom: 1 }} variant="contained" onClick={() => history.push("/create-ats")}>
            {t("create_ats")}
          </Button>
          <AtsesToolbar setAtses={setAtses} search={searchAtses} setSearch={setSearchAtses} setIsLoading={setIsLoading} />
          <AtsesTable
            isLoading={isLoading}
            atses={atses}
            handleShowPhoneNumberModal={handleShowPhoneNumberModal}
            handleShowDisclaimerModal={handleShowDisclaimerModal}
            setSelectedAts={setSelectedAts}
            onAtsProductionChange={onAtsProductionChange}
            onBetaFeaturesToggle={onBetaFeaturesToggle}
            onEmailNotificationsToggle={onEmailNotificationsToggle}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesAtses}
            page={currPageAtses}
            onChange={(e, newValue) => {
              newPageAtses(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <List sx={{ maxHeight: "100%", border: "solid #E4E4E4", borderRadius: 1, marginTop: 1 }} disablePadding>
            <ListItem
              secondaryAction={
                <Tooltip title={t("change_pagesize")}>
                  <IconButton
                    onClick={() => {
                      handleShowPageSizeModal();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemText primary={t("current_pagesize", { page: pageSize })} />
            </ListItem>
          </List>
        </TabPanel>
      </div>
    </div>
  );
};

export default Admin;
