import { MapContainer, TileLayer, Circle, Marker, Polygon } from "react-leaflet";
import { blueIcon, purpleIcon, greyIcon } from "../../../common/utils/markers";

export const PilotMap = ({
  highlightUpcoming,
  highlightEarlier,
  highlightErased,
  center,
  radius = 300,
  SetViewOnClick,
  restrictedZones,
  atses,
}) => {
  return (
    <>
      {center && (
        <MapContainer id="pilot-map" center={center} zoom={8} scrollWheelZoom={false}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {highlightUpcoming && (
            <Circle center={highlightUpcoming} radius={radius} fillColor="#00008b" fillOpacity={0.5} color="#00008b" opacity={0.2}>
              <Marker icon={blueIcon} position={highlightUpcoming} />
            </Circle>
          )}
          {highlightEarlier && (
            <Circle center={highlightEarlier} radius={radius} fillColor="#00008b" fillOpacity={0.5} color="#00008b" opacity={0.2}>
              <Marker icon={purpleIcon} position={highlightEarlier} />
            </Circle>
          )}
          {highlightErased && (
            <Circle center={highlightErased} radius={radius} fillColor="#00008b" fillOpacity={0.5} color="#00008b" opacity={0.2}>
              <Marker icon={greyIcon} position={highlightErased} />
            </Circle>
          )}
          <SetViewOnClick center={center} />
          {restrictedZones?.map((zone) => {
            if (zone.area !== null) return <Polygon key={zone.id} pathOptions={{ color: "red" }} positions={zone.area} opacity={0} />;
            else
              return (
                <Circle key={zone.id} center={[zone.lat, zone.lng]} radius={zone.radius} fillColor="#FF0000" color="#FF0000" opacity={0} />
              );
          })}
          {atses && (
            <>
              {atses.map((ats) => (
                <Polygon pathOptions={{ color: "red" }} key={ats.id} positions={ats.airspace} fillOpacity={0} />
              ))}
            </>
          )}
        </MapContainer>
      )}
    </>
  );
};
