import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { useTranslation } from "react-i18next";

const NoAts = () => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Ats")) history.push("/unauthorized");
  }, []);

  return (
    <div className="narrow-container">
      <div className="item">
        <div>
          <big>{t("account_no_ats")}</big>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default NoAts;
