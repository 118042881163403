import { useState, useEffect, useContext } from "react";
import isEqual from "lodash/isEqual";
import { TableHead, TableRow, TableBody, Table } from "@mui/material";
import { MapContainer, TileLayer, Circle, useMap, Polygon } from "react-leaflet";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BackButton } from "../../common/components/BackButton";
import { useQuery } from "../../common/hooks/hooks";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import { restrictedZonesApi, atsApi, settingsApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import { FormButton, StyledTableCell } from "../../common/utils/stylings";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const RestrictedZones = ({ setSelected }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [pageSize, setPageSize] = useState(5);
  const id = useQuery().get("id");
  const [restrictedZones, setRestrictedZones] = useState();
  const [center, setCenter] = useState();
  const [airspace, setAirspace] = useState();

  const [highlightedZone, setHighLightedZone] = useState();
  const [name, setName] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    const retrieveStandardRequestData = () => {
      setIsPageLoading(true);

      settingsApi
        .getPageSize()
        .then((res) => {
          setPageSize(res.data);

          Promise.all([atsApi.getAts(id), restrictedZonesApi.getRestrictedByAts(id, 1, res.data)])
            .then(([at, re]) => {
              const pagination = JSON.parse(re.headers.pagination);
              setAirspace(at.data.airspace);
              setName(at.data.name);
              setCenter([at.data.lat, at.data.lng]);
              setTotalPages(pagination.TotalPages);
              setRestrictedZones(re.data);
              setIsPageLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setError(err);
              setIsPageLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    retrieveStandardRequestData();
  }, []);

  function SetViewOnClick({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  const highlightZone = (request) => {
    if (isEqual(request, highlightedZone)) setHighLightedZone();
    else {
      setHighLightedZone(request);
      if (request.area !== null) setCenter([request.area[0][0], request.area[0][1]]);
      else setCenter([request.lat, request.lng]);
    }
  };

  const newPage = (p) => {
    setCurrPage(p);
    restrictedZonesApi
      .getRestrictedByAts(id, p, pageSize)
      .then((res) => {
        const pagination = JSON.parse(res.headers.pagination);
        setTotalPages(pagination.TotalPages);
        setRestrictedZones(res.data);
      })
      .catch((err) => console.log(err));
  };

  const trashRestrictedZone = (zone) => {
    setIsLoading(true);
    restrictedZonesApi.deleteRestrictedZone(zone.id).then((res) => {
      restrictedZonesApi
        .getRestrictedByAts(id, currPage, pageSize)
        .then((res) => {
          setRestrictedZones(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  if (error) {
    return <div className="standard-container">{error.toString()}</div>;
  }

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="narrow-container">
      <div className="item">
        {name && <BackButtonWithTitle title={t("restricted_areas", { ats: name })} />}
        <div className="map-container">
          <Map
            airspace={airspace}
            user={user}
            center={center}
            restrictedZones={restrictedZones}
            highlightedZone={highlightedZone}
            SetViewOnClick={SetViewOnClick}
          />
        </div>
      </div>
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      <div className="item">
        <RestrictedZonesTable
          id={id}
          setSelected={setSelected}
          restrictedZones={restrictedZones}
          highlightZone={highlightZone}
          setRestrictedZones={setRestrictedZones}
          trashRestrictedZone={trashRestrictedZone}
        />
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          shape="rounded"
          count={totalPages}
          page={currPage}
          onChange={(e, newValue) => {
            newPage(newValue);
          }}
        />
        <FormButton variant="contained" color="primary" onClick={() => history.push(`/create-restricted-zone?id=${id}`)}>
          {t("create_restricted_area")}
        </FormButton>
      </div>
    </div>
  );
};

export default RestrictedZones;

const Map = ({ SetViewOnClick, restrictedZones, airspace, highlightedZone, center }) => {
  return (
    <MapContainer id="map" center={center} zoom={9} scrollWheelZoom={true}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      {highlightedZone && (
        <>
          {highlightedZone.area !== null ? (
            <Polygon key={highlightedZone.id} pathOptions={{ color: "red" }} positions={highlightedZone.area} opacity={1} />
          ) : (
            <Circle
              key={highlightedZone.id}
              center={[highlightedZone.lat, highlightedZone.lng]}
              radius={highlightedZone.radius}
              fillColor="#FF0000"
              color="#FF0000"
              opacity={1}
            />
          )}
        </>
      )}
      {restrictedZones?.map((zone) => {
        if (zone.area !== null) return <Polygon key={zone.id} pathOptions={{ color: "red" }} positions={zone.area} opacity={0} />;
        else
          return (
            <Circle key={zone.id} center={[zone.lat, zone.lng]} radius={zone.radius} fillColor="#FF0000" color="#FF0000" opacity={0} />
          );
      })}
      <SetViewOnClick center={center} />
      <Polygon pathOptions={{ color: "red" }} positions={airspace} fillOpacity={0} />
    </MapContainer>
  );
};

const RestrictedZonesTable = ({ restrictedZones, highlightZone, trashRestrictedZone, setSelected, id }) => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>{t("name")}</StyledTableCell>
          <StyledTableCell></StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {restrictedZones?.map((zone) => {
          return (
            <TableRow hover key={zone.id} onClick={() => highlightZone(zone)}>
              <StyledTableCell>{zone.name} </StyledTableCell>
              <StyledTableCell align="right">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected(zone);
                    history.push(`/edit-restricted-zone?id=${id}`);
                  }}
                  title={t("edit_restricted_area")}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();

                    trashRestrictedZone(zone);
                  }}
                  title={t("remove_restricted_area")}
                >
                  <DeleteIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
