import React, { useState, useEffect } from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import Table from "@mui/material/Table";
import { TableHead, TableRow, TableBody, TableContainer, IconButton } from "@mui/material";
import { StyledPaper, LimitedBackdrop, StyledTableCellSecondary } from "../../../common/utils/stylings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

export const AtsesTable = ({ atses, isLoading, handleShowPhoneNumberModal, handleShowDisclaimerModal, setSelectedAts, onAtsProductionChange, onBetaFeaturesToggle, onEmailNotificationsToggle }) => {
  const { t } = useTranslation();

  let history = useHistory();

  const CustomRow = ({ ats }) => {
    const [open, setOpen] = useState(false);
    return (
      <TableBody>
        <TableRow key={ats.id}>
          <StyledTableCellSecondary>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCellSecondary>
          <StyledTableCellSecondary>{ats.name}</StyledTableCellSecondary>
          <StyledTableCellSecondary>{ats.phoneNumber}</StyledTableCellSecondary>
          <StyledTableCellSecondary>
            {ats.production ? (
              <IconButton
                disabled={isLoading}
                title={t("test_mode")}
                onClick={() => {
                  onAtsProductionChange(ats, false);
                }}
              >
                <ToggleOnIcon fontSize="large" color="primary" />
              </IconButton>
            ) : (
              <IconButton
                disabled={isLoading}
                title={t("production_mode")}
                onClick={() => {
                  onAtsProductionChange(ats, true);
                }}
              >
                <ToggleOffIcon fontSize="large" color="disabled" />
              </IconButton>
            )}
          </StyledTableCellSecondary>
          <StyledTableCellSecondary>
            {ats.betaFeatures ? (
              <IconButton
                disabled={isLoading}
                title={t("turn_beta_off")}
                onClick={() => {
                  onBetaFeaturesToggle(ats, false);
                }}
              >
                <ToggleOnIcon fontSize="large" color="primary" />
              </IconButton>
            ) : (
              <IconButton
                disabled={isLoading}
                title={t("turn_beta_on")}
                onClick={() => {
                  onBetaFeaturesToggle(ats, true);
                }}
              >
                <ToggleOffIcon fontSize="large" color="disabled" />
              </IconButton>
            )}
          </StyledTableCellSecondary>
          <StyledTableCellSecondary>
            {ats.emailNotifications ? (
              <IconButton
                disabled={isLoading}
                title={t("turn_email_notifications_off")}
                onClick={() => {
                  onEmailNotificationsToggle(ats, false);
                }}
              >
                <ToggleOnIcon fontSize="large" color="primary" />
              </IconButton>
            ) : (
              <IconButton
                disabled={isLoading}
                title={t("turn_email_notifications_on")}
                onClick={() => {
                  onEmailNotificationsToggle(ats, true);
                }}
              >
                <ToggleOffIcon fontSize="large" color="disabled" />
              </IconButton>
            )}
          </StyledTableCellSecondary>
        </TableRow>
        <TableRow>
          <StyledTableCellSecondary style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto">
              <List disablePadding dense={true}>
                <ListItem disablePadding dense={true}>
                  <ListItemButton
                    onClick={() => {
                      history.push(`/airspace?id=${ats.id}`);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("manage_airspace")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding dense={true}>
                  <ListItemButton
                    onClick={() => {
                      handleShowPhoneNumberModal();
                      setSelectedAts(ats);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("edit_phone_nr")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding dense={true}>
                  <ListItemButton
                    onClick={() => {
                      handleShowDisclaimerModal();
                      setSelectedAts(ats);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("edit_disclaimer")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding dense={true}>
                  <ListItemButton
                    onClick={() => {
                      history.push(`/restricted-zones?id=${ats.id}`);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("manage_restricted_areas")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding dense={true}>
                  <ListItemButton
                    onClick={() => {
                      history.push(`/standard-requests?id=${ats.id}`);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t("manage_maplayers")} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </StyledTableCellSecondary>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}></LimitedBackdrop>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCellSecondary></StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("name")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("phone")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("production")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("beta")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("email_notifications")}</StyledTableCellSecondary>
          </TableRow>
        </TableHead>
        {atses?.map((ats, index) => (
          <CustomRow key={ats.id} ats={ats} />
        ))}
      </Table>
    </TableContainer>
  );
};

export const PilotTable = ({ pilots, isLoading, onPrioUserChange, onLock, onUnlock, user }) => {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}></LimitedBackdrop>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCellSecondary>{t("name")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("email")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("phone")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("prio_users")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("locked_unlocked")}</StyledTableCellSecondary>
          </TableRow>
        </TableHead>
        <TableBody>
          {pilots?.map((user, index) => {
            return (
              <TableRow key={user.id}>
                <StyledTableCellSecondary>{user.displayName}</StyledTableCellSecondary>
                <StyledTableCellSecondary>{user.email}</StyledTableCellSecondary>
                <StyledTableCellSecondary>{user.phoneNumber}</StyledTableCellSecondary>
                <StyledTableCellSecondary>
                  {user.prioUser ? (
                    <IconButton
                      disabled={isLoading}
                      title={t("normal_mode")}
                      onClick={() => {
                        onPrioUserChange(user, false);
                      }}
                    >
                      <ToggleOnIcon fontSize="large" color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={isLoading}
                      title={t("prio_mode")}
                      onClick={() => {
                        onPrioUserChange(user, true);
                      }}
                    >
                      <ToggleOffIcon fontSize="large" color="disabled" />
                    </IconButton>
                  )}
                </StyledTableCellSecondary>
                <StyledTableCellSecondary>
                  {user.lockoutEnd ? (
                    <IconButton
                      disabled={isLoading}
                      onClick={() => {
                        onUnlock(user);
                      }}
                    >
                      <LockIcon fontSize="medium" color="error" />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={isLoading}
                      onClick={() => {
                        onLock(user);
                      }}
                    >
                      <LockOpenIcon fontSize="medium" color="success" />
                    </IconButton>
                  )}
                </StyledTableCellSecondary>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const AtsAccountsTable = ({ atsAccounts, isLoading = false }) => {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}></LimitedBackdrop>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCellSecondary>{t("email")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("ats_tab")}</StyledTableCellSecondary>
          </TableRow>
        </TableHead>
        {atsAccounts?.map((user, index) => {
          return (
            <TableBody key={index}>
              <TableRow key={index}>
                <StyledTableCellSecondary>{user.email}</StyledTableCellSecondary>
                {user.atsName && <StyledTableCellSecondary>{user.atsName}</StyledTableCellSecondary>}
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
};

export const AdminTable = ({ admins, isLoading }) => {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}></LimitedBackdrop>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCellSecondary>{t("name")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("email")}</StyledTableCellSecondary>
            <StyledTableCellSecondary>{t("phone")}</StyledTableCellSecondary>
          </TableRow>
        </TableHead>
        {admins?.map((user, index) => {
          return (
            <TableBody key={index}>
              <TableRow key={index}>
                <StyledTableCellSecondary>{user.displayName}</StyledTableCellSecondary>
                <StyledTableCellSecondary>{user.email}</StyledTableCellSecondary>
                <StyledTableCellSecondary>{user.phoneNumber}</StyledTableCellSecondary>
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
};
