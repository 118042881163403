import React from "react";
import { useQuery } from "../../common/hooks/hooks";
import { FormButton } from "../../common/utils/stylings";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const RegisterSuccess = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const email = useQuery().get("email");

  const handleConfirmEmailResend = () => {
    accountApi
      .resendEmailConfirm(email)
      .then(() => {
        handleOpenSnackbar("success", t("confirmation_email_sent_again"));
      })
      .catch((err) => {
        console.log(err);
        handleOpenSnackbar("error", t("generic_error"));
      });
  };
  return (
    <div className="narrow-container">
      <div className="item">
        <div>
          <big>{t("confirmation_email_sent")}</big>
        </div>
        <div style={{ marginTop: 5 }}>
          <FormButton variant="contained" color="primary" type="submit" onClick={handleConfirmEmailResend}>
            {t("send_again")}
          </FormButton>
          <FormButton
            sx={{ marginLeft: 1 }}
            variant="outlined"
            type="submit"
            onClick={() => {
              history.push("/login");
            }}
          >
            {t("go_to_login")}
          </FormButton>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
