import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { settingsApi } from "../../../api/api";

export const PageSizeModal = ({ show, handleClose, isLoading, pageSize, setPageSize }) => {
  const formik = useFormik({
    initialValues: {
      pageSize: "",
      error: null,
    },
    onSubmit: (fields) => {
      settingsApi
        .updatePageSize(fields.pageSize)
        .then((res) => {
          setPageSize(res.data);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("pageSize", pageSize);
  }, []);

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              style={{ width: "60%" }}
              fullWidth
              size="small"
              id="pageSize"
              name="pageSize"
              label="Sidstorlek"
              value={formik.values.pageSize}
              onChange={formik.handleChange}
              error={formik.touched.pageSize && Boolean(formik.errors.pageSize)}
              helperText={formik.touched.pageSize && formik.errors.pageSize}
            />
            <Button style={{ margin: 5 }} variant="contained" color="success" type="submit">
              Spara
            </Button>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  );
};
