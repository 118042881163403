import React, { useState, useContext, useEffect } from "react";
import isEqual from "lodash/isEqual";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useMap } from "react-leaflet";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Pagination from "@mui/material/Pagination";
import { scrollToTop } from "../../common/utils/functions";
import { restrictedZonesApi, atsApi, requestsApi, settingsApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { PilotMap } from "./components/PilotMap";
import { ShowPhoneNumberModal } from "./components/ShowPhoneNumberModal";
import { UpcomingTable, EarlierTable, ErasedTable } from "./components/PilotTables";
import { UpcomingToolbar } from "./components/UpcomingToolbar";
import { EarlierToolbar } from "./components/EarlierToolbar";
import { ErasedToolbar } from "./components/ErasedToolbar";
import { useTranslation } from "react-i18next";

const Pilot = ({ setSelected, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [pageSize, setPageSize] = useState(5);
  const [upcoming, setUpcoming] = useState([]);
  const [earlier, setEarlier] = useState([]);
  const [erased, setErased] = useState([]);
  const [restrictedZones, setRestrictedZones] = useState([]);
  const [atses, setAtses] = useState([]);
  const [center, setCenter] = useState();
  const [radius, setRadius] = useState();
  const [ats, setAts] = useState();
  const [show, setShow] = useState(false);
  const [highlightUpcoming, setHighlightUpcoming] = useState();
  const [highlightEarlier, setHighlightEarlier] = useState();
  const [highlightErased, setHighlightErased] = useState();
  const [currPageUpcoming, setCurrPageUpcoming] = useState(1);
  const [currPageEarlier, setCurrPageEarlier] = useState(1);
  const [currPageErased, setCurrPageErased] = useState(1);
  const [totalPagesUpcoming, setTotalPagesUpcoming] = useState(1);
  const [totalPagesEarlier, setTotalPagesEarlier] = useState(1);
  const [totalPagesErased, setTotalPagesErased] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tab, setTab] = useState(0);
  const [searchUpcoming, setSearchUpcoming] = useState("");
  const [searchEarlier, setSearchEarlier] = useState("");
  const [searchErased, setSearchErased] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Pilot")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    retrievePilotData();
    return () => {
      setSearchUpcoming("");
      setSearchEarlier("");
      setSearchErased("");
    };
  }, []);

  useEffect(() => {
    setSelected();
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        if (!center) setCenter([pos.coords.latitude, pos.coords.longitude]);
      },
      (err) => {},
      [
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      ]
    );
  }, []);

  function SetViewOnClick({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  const retrievePilotData = () => {
    setIsPageLoading(true);
    settingsApi
      .getPageSize()
      .then((res) => {
        setPageSize(res.data);

        Promise.all([
          requestsApi.getUpcomingByUser(1, res.data, user.email),
          requestsApi.getEarlierByUser(1, res.data, user.email),
          requestsApi.getErasedByUser(1, res.data, user.email),
          restrictedZonesApi.getRestrictedZones(),
          atsApi.getAtsesProduction(),
        ])
          .then(([upc, ear, era, res, ats]) => {
            const paginationUpcoming = JSON.parse(upc.headers.pagination);
            const paginationEarlier = JSON.parse(ear.headers.pagination);
            const paginationErased = JSON.parse(era.headers.pagination);
            setTotalPagesUpcoming(paginationUpcoming.TotalPages);
            setTotalPagesEarlier(paginationEarlier.TotalPages);
            setTotalPagesErased(paginationErased.TotalPages);

            setUpcoming(upc.data);
            setEarlier(ear.data);
            setErased(era.data);
            setRestrictedZones(res.data);
            setAtses(ats.data);
            setIsPageLoading(false);
            try {
              if (!center) setCenter([upc.data[0].lat, upc.data[0].lng]);
            } catch (error) {
              // console.log(error);
            }
          })
          .catch((err) => {
            // console.log("Något gick fel vid hämtning av data", err.toString());
            setError(err.toString());
            setIsPageLoading(false);
          });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const onErase = (request) => {
    if (request.activatedDate && !request.endedDate) {
      handleOpenSnackbar("error", t("request_is_active"));
      return;
    }
    setIsLoading(true);
    requestsApi.patchRequest(request.id, "/erased", "replace", true).then((res) => {
      requestsApi
        .getUpcomingByUser(currPageUpcoming, pageSize, user.email)
        .then((res) => {
          setUpcoming(res.data);
          const paginationUpcoming = JSON.parse(res.headers.pagination);
          setTotalPagesUpcoming(paginationUpcoming.TotalPages);
          setHighlightUpcoming();
          requestsApi.getErasedByUser(currPageErased, pageSize, user.email).then((res) => {
            setErased(res.data);
            const paginationErased = JSON.parse(res.headers.pagination);
            setTotalPagesErased(paginationErased.TotalPages);
            setCurrPageErased(1);
            setIsLoading(false);
          });
        })
        .catch((err) => {
          // console.log(err);
          setError(err);
          setIsLoading(false);
        });
    });
  };

  const onHighlightUpcoming = (e, request) => {
    const loc = [request.lat, request.lng];
    if (highlightUpcoming && isEqual(loc, [highlightUpcoming.lat, highlightUpcoming.lng])) {
      setHighlightUpcoming();
    } else {
      setHighlightUpcoming(request);
      restrictedZonesApi.getRestrictedZones().then((res) => setRestrictedZones(res.data));
      setCenter([request.lat, request.lng]);
      setRadius(request.radius);
    }
  };

  const onHighlightEarlier = (e, request) => {
    const loc = [request.lat, request.lng];
    if (highlightEarlier && isEqual(loc, [highlightEarlier.lat, highlightEarlier.lng])) {
      setHighlightEarlier();
    } else {
      setHighlightEarlier(request);
      restrictedZonesApi.getRestrictedZones().then((res) => setRestrictedZones(res.data));
      setCenter([request.lat, request.lng]);
      setRadius(request.radius);
    }
  };

  const onHighlightErased = (e, request) => {
    const loc = [request.lat, request.lng];
    if (highlightErased && isEqual(loc, [highlightErased.lat, highlightErased.lng])) {
      setHighlightErased();
    } else {
      setHighlightErased(request);
      restrictedZonesApi.getRestrictedZones().then((res) => setRestrictedZones(res.data));
      setCenter([request.lat, request.lng]);
      setRadius(request.radius);
    }
  };

  const newPageUpcoming = (p) => {
    setIsLoading(true);
    setCurrPageUpcoming(p);
    if (!searchUpcoming) {
      requestsApi
        .getUpcomingByUser(p, pageSize, user.email)
        .then((res) => {
          setUpcoming(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setError(err);
        });
    } else {
      requestsApi
        .searchUpcomingByUser(p, pageSize, user.email, searchUpcoming)
        .then((res) => {
          setUpcoming(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setError(err);
        });
    }
  };

  const newPageEarlier = (p) => {
    setIsLoading(true);
    setCurrPageEarlier(p);
    if (!searchUpcoming) {
      requestsApi
        .getEarlierByUser(p, pageSize, user.email)
        .then((res) => {
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setError(err);
        });
    } else {
      requestsApi
        .searchEarlierByUser(p, pageSize, user.email, searchEarlier)
        .then((res) => {
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setError(err);
        });
    }
  };
  const newPageErased = (p) => {
    setIsLoading(true);
    setCurrPageErased(p);
    if (!searchErased) {
      requestsApi
        .getErasedByUser(p, pageSize, user.email)
        .then((res) => {
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setError(err);
        });
    } else {
      requestsApi
        .searchErasedByUser(p, pageSize, user.email, searchErased)
        .then((res) => {
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          setError(err);
        });
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="standard-container">
      <div className="item">
        <ShowPhoneNumberModal show={show} handleClose={handleClose} ats={ats} />
        {center && (
          <div className="map-container">
            <PilotMap
              user={user}
              atses={atses}
              center={center}
              radius={radius}
              restrictedZones={restrictedZones}
              SetViewOnClick={SetViewOnClick}
              highlightUpcoming={highlightUpcoming}
              highlightEarlier={highlightEarlier}
              highlightErased={highlightErased}
            />
            {window.innerWidth < 1000 && (
              <Button
                startIcon={<MyLocationIcon />}
                disabled={isLoading}
                color="info"
                sx={{ marginTop: 1, textTransform: "none" }}
                variant="contained"
                size="small"
                title={t("mark_my_position")}
                onClick={() => {
                  try {
                    navigator.geolocation.getCurrentPosition(
                      (pos) => {
                        setCenter([pos.coords.latitude, pos.coords.longitude]);
                      },
                      (err) => {
                        handleOpenSnackbar("error", t("location_not_found"));
                      },
                      [
                        {
                          enableHighAccuracy: true,
                          timeout: 5000,
                          maximumAge: 0,
                        },
                      ]
                    );
                  } catch (err) {}
                }}
              >
                {t("my_position")}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="item">
        <div>
          <Button
            disabled={isLoading}
            size={window.innerWidth < 500 ? "small" : "medium"}
            variant="contained"
            color="primary"
            onClick={() => history.push("/create-request")}
          >
            {t("create_request")}
          </Button>
        </div>
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
            setHighlightUpcoming();
            setHighlightEarlier();
            setHighlightErased();
          }}
          aria-label="tabs"
        >
          <Tab label={t("upcoming")} />
          <Tab label={t("earlier")} />
          <Tab label={t("removed")} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <UpcomingToolbar
            setUpcoming={setUpcoming}
            setHighlightUpcoming={setHighlightUpcoming}
            setCurrPageUpcoming={setCurrPageUpcoming}
            setTotalPagesUpcoming={setTotalPagesUpcoming}
            search={searchUpcoming}
            setSearch={setSearchUpcoming}
            setIsLoading={setIsLoading}
            pageSize={pageSize}
          />
          <UpcomingTable
            upcoming={upcoming}
            setUpcoming={setUpcoming}
            onHighlightUpcoming={onHighlightUpcoming}
            highlightUpcoming={highlightUpcoming}
            setSelected={setSelected}
            isLoading={isLoading}
            handleShow={handleShow}
            setAts={setAts}
            onErase={onErase}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesUpcoming}
            page={currPageUpcoming}
            onChange={(e, newValue) => {
              newPageUpcoming(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <EarlierToolbar
            setEarlier={setEarlier}
            setHighlightEarlier={setHighlightEarlier}
            setCurrPageEarlier={setCurrPageEarlier}
            setTotalPagesEarlier={setTotalPagesEarlier}
            search={searchEarlier}
            setSearch={setSearchEarlier}
            setIsLoading={setIsLoading}
            pageSize={pageSize}
          />
          <EarlierTable
            onHighlightEarlier={onHighlightEarlier}
            highlightEarlier={highlightEarlier}
            earlierRequests={earlier}
            setSelected={setSelected}
            isLoading={isLoading}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesEarlier}
            page={currPageEarlier}
            onChange={(e, newValue) => {
              newPageEarlier(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ErasedToolbar
            setErased={setErased}
            setHighlightErased={setHighlightErased}
            setCurrPageErased={setCurrPageErased}
            setTotalPagesErased={setTotalPagesErased}
            search={searchErased}
            setSearch={setSearchErased}
            setIsLoading={setIsLoading}
            pageSize={pageSize}
          />
          <ErasedTable
            erasedRequests={erased}
            onHighlightErased={onHighlightErased}
            highlightErased={highlightErased}
            setSelected={setSelected}
            isLoading={isLoading}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesErased}
            page={currPageErased}
            onChange={(e, newValue) => {
              newPageErased(newValue);
            }}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default Pilot;
