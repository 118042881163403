import React, { useEffect } from "react";
import { MapContainer, TileLayer, Circle, Polygon, Marker, Popup, Tooltip } from "react-leaflet";
import { colorCodeMarker, colorCodeMarkerHighlight, convertToLocal, getTime, getDate, colorCodeColor } from "../../../common/utils/functions";
import { droneIcon, greyIcon } from "../../../common/utils/markers";
import { useTranslation } from "react-i18next";
import * as turf from "@turf/turf";

export const AtsMap = ({
  active,
  user,
  zones,
  previewZone,
  toggleZones,
  highlightActive,
  highlightUpcoming,
  highlightEarlier,
  highlightErased,
  ctrs,
  restrictedAreas,
  runwayBuffers,
  helipadBuffers,
  drones,
}) => {
  const { t } = useTranslation();

  return (
    <MapContainer id="map" center={user && user.atsLat && user.atsLng ? [user.atsLat, user.atsLng] : [62.38583179, 16.321998712]} zoom={9} scrollWheelZoom={true}>
      <TileLayer noWrap={true} zIndex={-1} attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
      {active?.map((request) => {
        return (
          <React.Fragment key={request.id}>
            {request.geoPolygon && request.geoPolygon.length > 0 && (
              <Polygon
                key={request.id}
                pathOptions={{
                  color: "#00008b",
                  fillColor: colorCodeColor(request.positionStatus),
                }}
                positions={request.geoPolygon}
                opacity={0.5}
                fillOpacity={0.2}
              >
                <Marker icon={colorCodeMarker(request.positionStatus)} position={[request.lat, request.lng]}>
                  <Popup>
                    <b>
                      {t("id")}: {request.id} <br /> {t("pilot")}: {request.displayName}
                    </b>
                  </Popup>
                </Marker>
                {request.geoPolygonBuffer && (
                  <Polygon
                    pathOptions={{
                      color: "#00008b",
                      fillColor: colorCodeColor(request.positionStatus),
                    }}
                    positions={request.geoPolygonBuffer}
                    opacity={0.5}
                    fillOpacity={0.2}
                  ></Polygon>
                )}
              </Polygon>
            )}
          </React.Fragment>
        );
      })}
      {drones?.map((drone) => {
        return (
          <Circle key={drone.id} center={[drone.lat, drone.lng]} radius={drone.radius_in_meters} fillColor="#00008b" fillOpacity={0.1} color="#00008b" opacity={0.2}>
            <Marker icon={droneIcon} position={[drone.lat, drone.lng]}>
              <Popup>
                <p>
                  <strong>{drone.height_above_GND_in_meters}m</strong> {t("above_ground")}
                  <br />
                  {t("flight_start")} {getTime(convertToLocal(drone.planned_start_unix))} ({getDate(convertToLocal(drone.planned_start_unix))}
                  )
                  <br />
                  <strong>
                    {t("flight_end")} {getTime(convertToLocal(drone.planned_stop_unix))}
                  </strong>{" "}
                  ({getDate(convertToLocal(drone.planned_stop_unix))})
                  <br />
                  {/* {drone.is_police ? <em>{t("another_drone_is_at_location")}</em> : <em>{t("another_drone_is_at_location")}</em>} */}
                </p>
              </Popup>
            </Marker>
          </Circle>
        );
      })}
      {highlightActive && (
        <>
          {highlightActive.geoPolygon && highlightActive.geoPolygon.length > 0 && (
            <Polygon
              key={highlightActive.id}
              pathOptions={{
                color: colorCodeColor(highlightActive.positionStatus),
              }}
              positions={highlightActive.geoPolygon}
              opacity={0.2}
              fillOpacity={0.2}
            >
              <Marker icon={colorCodeMarkerHighlight(highlightActive.positionStatus)} position={[highlightActive.lat, highlightActive.lng]}>
                <Popup>
                  <b>
                    {t("id")}: {highlightActive.id} <br /> {t("pilot")}: {highlightActive.displayName}
                  </b>
                </Popup>
              </Marker>
            </Polygon>
          )}
          {highlightActive.geoPolygonBuffer && (
            <Polygon
              pathOptions={{
                color: colorCodeColor(highlightActive.positionStatus),
              }}
              positions={highlightActive.geoPolygonBuffer}
              opacity={0.2}
              fillOpacity={0.2}
            ></Polygon>
          )}
        </>
      )}
      {highlightUpcoming && (
        <>
          {highlightUpcoming.geoPolygon && highlightUpcoming.geoPolygon.length > 0 && (
            <Polygon key={highlightUpcoming.id} pathOptions={{ color: "#00008b" }} positions={highlightUpcoming.geoPolygon} opacity={0.2} fillOpacity={0.2}>
              <Marker icon={greyIcon} position={[highlightUpcoming.lat, highlightUpcoming.lng]}>
                <Popup>
                  <b>
                    {t("id")}: {highlightUpcoming.id} <br /> {t("pilot")}: {highlightUpcoming.displayName}
                  </b>
                </Popup>
              </Marker>
            </Polygon>
          )}
          {highlightUpcoming.geoPolygonBuffer && <Polygon pathOptions={{ color: "#00008b" }} positions={highlightUpcoming.geoPolygonBuffer} opacity={0.2} fillOpacity={0.2}></Polygon>}
        </>
      )}
      {highlightEarlier && (
        <>
          {highlightEarlier.geoPolygon && highlightEarlier.geoPolygon.length > 0 && (
            <Polygon key={highlightEarlier.id} pathOptions={{ color: "#00008b" }} positions={highlightEarlier.geoPolygon} opacity={0.2} fillOpacity={0.2}>
              <Marker icon={greyIcon} position={[highlightEarlier.lat, highlightEarlier.lng]}>
                <Popup>
                  <b>
                    {t("id")}: {highlightEarlier.id} <br /> {t("pilot")}: {highlightEarlier.displayName}
                  </b>
                </Popup>
              </Marker>
            </Polygon>
          )}
          {highlightEarlier.geoPolygonBuffer && <Polygon pathOptions={{ color: "#00008b" }} positions={highlightEarlier.geoPolygonBuffer} opacity={0.2} fillOpacity={0.2}></Polygon>}
        </>
      )}

      {highlightErased && (
        <>
          {highlightErased.geoPolygon && highlightErased.geoPolygon.length > 0 && (
            <Polygon key={highlightErased.id} pathOptions={{ color: "#00008b" }} positions={highlightErased.geoPolygon} opacity={0.2} fillOpacity={0.2}>
              <Marker icon={greyIcon} position={[highlightErased.lat, highlightErased.lng]}>
                <Popup>
                  <b>
                    {t("id")}: {highlightErased.id} <br /> {t("pilot")}: {highlightErased.displayName}
                  </b>
                </Popup>
              </Marker>
            </Polygon>
          )}
          {highlightErased.geoPolygonBuffer && <Polygon pathOptions={{ color: "#00008b" }} positions={highlightErased.geoPolygonBuffer} opacity={0.2} fillOpacity={0.2}></Polygon>}
        </>
      )}
      {toggleZones && (
        <div>
          {restrictedAreas?.map((area) => {
            return (
              <Polygon key={area.lfv_id} pathOptions={{ color: "red" }} positions={area.coordinates} opacity={0} fillOpacity={0.2}>
                <Tooltip>{area.fullName}</Tooltip>
              </Polygon>
            );
          })}
          {helipadBuffers?.map((buffer) => {
            return (
              <Circle key={buffer.lfv_id} center={[buffer.latitude, buffer.longitude]} radius={1000} fillColor="red" opacity={0} fillOpacity={0.2}>
                <Tooltip>{buffer.name}</Tooltip>
              </Circle>
            );
          })}
          {runwayBuffers?.map((buffer) => {
            return (
              <Polygon key={buffer.lfv_id} pathOptions={{ color: "red" }} positions={buffer.coordinates} opacity={0} fillOpacity={0.2}>
                <Tooltip>{buffer.name}</Tooltip>
              </Polygon>
            );
          })}
        </div>
      )}
      {ctrs?.map((ctr) => {
        return (
          <Polygon key={ctr.lfv_id} pathOptions={{ color: "red" }} positions={ctr.coordinates} fillOpacity={0} zIndex={0}>
            <Tooltip>{ctr.name}</Tooltip>
          </Polygon>
        );
      })}
      {zones && (
        <div>
          {zones.map((zone) => {
            return <Polygon key={zone.id} pathOptions={{ color: "#008000" }} positions={zone.area} />;
          })}
        </div>
      )}
      {previewZone && <Polygon pathOptions={{ color: "#808080" }} positions={previewZone} />}
    </MapContainer>
  );
};
