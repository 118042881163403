import * as Yup from "yup";
import { BackButton } from "../../common/components/BackButton";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { FormButton } from "../../common/utils/stylings";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import Paper from "@mui/material/Paper";

const ChangeEmailRequest = ({ handleOpenSnackbar }) => {
  const { user, setUser } = useContext(AccountContext);
  const { t } = useTranslation();

  let history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email_invalid")).required(t("email_required")),
  });

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 50);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      accountApi
        .changeEmailConfirmationLink(user.email, fields.email)
        .then((res) => {
          handleOpenSnackbar("success", res.data);

          const e = user.email;

          localStorage.clear();
          setUser(null);
          history.push(`/change-email-verification-sent?email=${e}&newEmail=${fields.email}`);
        })
        .catch((err) => {
          if (err) {
            handleOpenSnackbar("error", err.response?.data);
          } else {
            handleOpenSnackbar("error", t("generic_error"));
          }
        });
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <Paper sx={{ padding: 2 }} elevation={3}>
            <BackButtonWithTitle title={t("change_email")} />
            <div className="item">
              <div>
                <TextField
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  label={t("new_email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <FormButton type="submit" variant={"contained"}>
                {t("send")}
              </FormButton>
            </div>
          </Paper>
        </div>
      </form>
    </div>
  );
};

export default ChangeEmailRequest;
