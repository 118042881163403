import * as Yup from "yup";
import { BackButton } from "../../common/components/BackButton";
import { FormButton } from "../../common/utils/stylings";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../common/hooks/hooks";
import { accountApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const ChangePassword = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, t("password_length")).required(t("new_password_required")),
    confirmPassword: Yup.string().min(8, t("password_length")).required(t("repeat_new_password_required")),
  });

  const email = useQuery().get("email");
  const token = useQuery().get("token");
  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      if (fields.password === fields.confirmPassword) {
        accountApi.resetPassword(token, email, fields.password).then((res) => {
          handleOpenSnackbar("success", t("password_changed"));
          setTimeout(() => {
            history.push("/");
          }, 300);
        });
      } else handleOpenSnackbar("error", t("password_match"));
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="narrow-container">
          <BackButtonWithTitle title={t("change_password")} />
          <div className="item">
            <div>
              <TextField
                variant="standard"
                fullWidth
                type="password"
                id="password"
                name="password"
                label={t("new_password")}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                variant="standard"
                fullWidth
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label={t("repeat_new_password")}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
            </div>
            <FormButton type="submit" variant={"contained"}>
              {t("save")}
            </FormButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
