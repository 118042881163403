import { COUNTRY_CODES } from "./constants";
import { convertToLocal } from "./functions";

export const generatePrintStrip = (request) => {
  const name = request.displayName; //pilot name from dronerequest AspNetUsers table
  const adress = request.address; // Address from dronerequest Requests table POSSIBLY AN ISSUE WITH ENCODING ÅÄÖ HERE, MIGHT HAVE TO TROUBLESHOOT ONCE FUNCTIONALITY IS IN PRODUCTION
  const status = request.prioUser ? "POL/RESC" : ""; //use this constant string "POL/RESC" if dronerequest DB pilot table has prioUser == true
  const duration = request.estimatedFlightTime; // use EstimatedFlightTime from requests table and append " min" (60)
  const phone = formatPhoneNumber(request.phoneNumber); // use pilot phone from AspNetUsers table, make a space after country code (+46 768164888).
  const startTime = formatStartTime(convertToLocal(request.plannedDate)); //use request table "plannedDate" formatted to display only hour HH and minute ii (1630)
  const requestId = request.id; //use id from requests Table
  const metersGround = request.height; //use Height from requests table
  const feetGround = meters2feet(metersGround);

  return (
    `<?xml version="1.0" encoding="utf-8"?>
  <CustomParams>
     <CustomParam class="text" id="Callsign">
        <text>` +
    name +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="AType">
        <text>` +
    `UAS` +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="Class">
        <text></text>
     </CustomParam>
     <CustomParam class="text" id="Reg">
        <text>` +
    adress +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="FRUL">
        <text></text>
     </CustomParam>
     <CustomParam class="text" id="Status">
        <text>` +
    status +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="Dep">
        <text>` +
    duration +
    ` min</text>
     </CustomParam>
     <CustomParam class="text" id="Arr">
        <text></text>
     </CustomParam>
     <CustomParam class="text" id="Speed">
        <text>` +
    phone +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="DOF">
        <text></text>
     </CustomParam>
     <CustomParam class="text" id="EOBT">
        <text>` +
    startTime +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="Altitude_Elapsedtime">
        <text>#` +
    requestId +
    `</text>
     </CustomParam>
     <CustomParam class="text" id="Deptime">
        <text>Dronerequest</text>
     </CustomParam>
     <CustomParam class="text" id="Route1">
        <text>` +
    metersGround +
    `m GND</text>
     </CustomParam>
     <CustomParam class="text" id="Route2">
        <text>` +
    feetGround +
    `ft GND</text>
     </CustomParam>
  </CustomParams>`
  );
};

const meters2feet = (m) => {
  const metersGround = m;
  const feetExact = metersGround * 3.281;
  const feetInt = parseInt(feetExact / 100);
  const feetRounded = feetInt + 1;
  const feetHundreds = feetRounded * 100;
  return feetHundreds;
};

const formatPhoneNumber = (value) => {
  for (let i = 0; i < COUNTRY_CODES.length; i++) {
    if (value.includes(COUNTRY_CODES[i])) {
      let code_size = COUNTRY_CODES[i].length;
      return value.substring(0, code_size) + " " + value.substring(code_size);
      //   return { code: value.substring(0, code_size), number: value.substring(code_size) };
    }
  }
};

const formatStartTime = (d) => {
  const date = d.split(" ");
  const time = date[1].split(":");
  return `${time[0]}${time[1]}`;
};
