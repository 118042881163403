import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { convertToLocal, scrollToTop } from "../../common/utils/functions";
import CircularProgress from "@mui/material/CircularProgress";
import { TableHead, TableRow, TableBody } from "@mui/material";
import Table from "@mui/material/Table";
import { InfoModal } from "./components/InfoModal";
import { BackButton } from "../../common/components/BackButton";
import { requestsApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import InfoIcon from "@mui/icons-material/Info";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { StyledPaper, LimitedBackdrop, StyledTableCell } from "../../common/utils/stylings";
import TableContainer from "@mui/material/TableContainer";
import { PilotHistoryUpcomingToolbar } from "./components/PilotHistoryUpcomingToolbar";
import { PilotHistoryEarlierToolbar } from "./components/PilotHistoryEarlierToolbar";
import { PilotHistoryErasedToolbar } from "./components/PilotHistoryErasedToolbar";
import Pagination from "@mui/material/Pagination";
import { settingsApi } from "../../api/api";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const PilotHistory = ({ selected, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [pageSize, setPageSize] = useState(5);
  const [upcoming, setUpcoming] = useState([]);
  const [earlier, setEarlier] = useState([]);
  const [erased, setErased] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState();
  const [searchUpcoming, setSearchUpcoming] = useState("");
  const [searchEarlier, setSearchEarlier] = useState("");
  const [searchErased, setSearchErased] = useState("");
  const [currPageUpcoming, setCurrPageUpcoming] = useState(1);
  const [currPageEarlier, setCurrPageEarlier] = useState(1);
  const [currPageErased, setCurrPageErased] = useState(1);
  const [totalPagesUpcoming, setTotalPagesUpcoming] = useState(1);
  const [totalPagesEarlier, setTotalPagesEarlier] = useState(1);
  const [totalPagesErased, setTotalPagesErased] = useState(1);
  const [error, setError] = useState();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showRequestInfo, setShowRequestInfo] = useState(false);

  const [tab, setTab] = useState(1);

  const handleCloseRequestInfo = () => setShowRequestInfo(false);
  const handleShowRequestInfo = () => setShowRequestInfo(true);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    if (!selected) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Ats")) history.push("/unauthorized");

    scrollToTop();
    return () => {
      // cleanup
      setSearchUpcoming("");
      setSearchEarlier("");
      setSearchErased("");
    };
  }, []);

  useEffect(() => {
    const retrievePilotData = () => {
      if (selected && selected.email) {
        setIsPageLoading(true);

        settingsApi
          .getPageSize()
          .then((res) => {
            setPageSize(res.data);

            Promise.all([
              requestsApi.getUpcomingByUser(1, res.data, selected.email),
              requestsApi.getEarlierByUser(1, res.data, selected.email),
              requestsApi.getErasedByUser(1, res.data, selected.email),
            ])
              .then(([upc, ear, era]) => {
                const paginationUpcoming = JSON.parse(upc.headers.pagination);
                const paginationEarlier = JSON.parse(ear.headers.pagination);
                const paginationErased = JSON.parse(era.headers.pagination);
                setTotalPagesUpcoming(paginationUpcoming.TotalPages);
                setTotalPagesEarlier(paginationEarlier.TotalPages);
                setTotalPagesErased(paginationErased.TotalPages);

                setUpcoming(upc.data);
                setEarlier(ear.data);
                setErased(era.data);
                setIsPageLoading(false);
              })
              .catch((err) => {
                setError(err);
                setIsPageLoading(false);
              });
          })
          .catch((err) => {});
      }
    };
    try {
      retrievePilotData();
    } catch (e) {
      history.goBack();
    }
  }, []);

  const newPageUpcoming = (p) => {
    setIsLoading(true);
    setCurrPageUpcoming(p);
    if (!searchUpcoming) {
      requestsApi
        .getUpcomingByUser(p, pageSize, selected.email)
        .then((res) => {
          setUpcoming(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    } else {
      requestsApi
        .searchUpcomingByUser(p, pageSize, selected.email, searchUpcoming)
        .then((res) => {
          setUpcoming(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  };

  const newPageEarlier = (p) => {
    setIsLoading(true);
    setCurrPageEarlier(p);
    if (!searchUpcoming) {
      requestsApi
        .getEarlierByUser(p, pageSize, selected.email)
        .then((res) => {
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    } else {
      requestsApi
        .searchEarlierByUser(p, pageSize, selected.email, searchEarlier)
        .then((res) => {
          setEarlier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  };
  const newPageErased = (p) => {
    setIsLoading(true);
    setCurrPageErased(p);
    if (!searchErased) {
      requestsApi
        .getErasedByUser(p, pageSize, selected.email)
        .then((res) => {
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    } else {
      requestsApi
        .searchErasedByUser(p, pageSize, selected.email, searchErased)
        .then((res) => {
          setErased(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="standard-container">
      {selected && <BackButtonWithTitle title={selected.displayName} />}
      <div className="item">
        <InfoModal atsId={user.atsId} atsBetaFeatures={user.atsBetaFeatures} show={showRequestInfo} handleClose={handleCloseRequestInfo} selectedRequest={selectedRequest} />
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
          }}
          aria-label="tabs"
        >
          <Tab label={t("upcoming")} />
          <Tab label={t("earlier")} />
          <Tab label={t("removed")} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <PilotHistoryUpcomingToolbar selectedUser={selected && selected.email} search={searchUpcoming} setSearch={setSearchUpcoming} setUpcoming={setUpcoming} setIsLoading={setIsLoading} />
          <UpcomingTable upcomingRequests={upcoming} setSelectedRequest={setSelectedRequest} isLoading={isLoading} handleShowRequestInfo={handleShowRequestInfo} />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesUpcoming}
            page={currPageUpcoming}
            onChange={(e, newValue) => {
              newPageUpcoming(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <PilotHistoryEarlierToolbar selectedUser={selected && selected.email} search={searchEarlier} setSearch={setSearchEarlier} setEarlier={setEarlier} setIsLoading={setIsLoading} />
          <EarlierTable earlierRequests={earlier} setSelectedRequest={setSelectedRequest} isLoading={isLoading} handleShowRequestInfo={handleShowRequestInfo} />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesEarlier}
            page={currPageEarlier}
            onChange={(e, newValue) => {
              newPageEarlier(newValue);
            }}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <PilotHistoryErasedToolbar selectedUser={selected && selected.email} search={searchErased} setSearch={setSearchErased} setErased={setErased} setIsLoading={setIsLoading} />
          <ErasedTable erasedRequests={erased} setSelectedRequest={setSelectedRequest} isLoading={isLoading} handleShowRequestInfo={handleShowRequestInfo} />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
            shape="rounded"
            count={totalPagesErased}
            page={currPageErased}
            onChange={(e, newValue) => {
              newPageErased(newValue);
            }}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default PilotHistory;

const UpcomingTable = ({ upcomingRequests, setSelectedRequest, handleShowRequestInfo, isLoading }) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    return (
      <TableRow hover key={request.id}>
        <StyledTableCell>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRequest(request);
              handleShowRequestInfo();
            }}
          >
            <InfoIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{request.id}</StyledTableCell>
        <StyledTableCell>{request.address}</StyledTableCell>
        <StyledTableCell>{request.ats?.name}</StyledTableCell>
        <StyledTableCell>{convertToLocal(request.plannedDate)}</StyledTableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("ats")}</StyledTableCell>
            <StyledTableCell>{t("planned_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {upcomingRequests?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EarlierTable = ({ earlierRequests, setSelectedRequest, handleShowRequestInfo, isLoading }) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    return (
      <TableRow hover key={request.id}>
        <StyledTableCell>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRequest(request);
              handleShowRequestInfo();
            }}
          >
            <InfoIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{request.id}</StyledTableCell>
        <StyledTableCell>{request.address}</StyledTableCell>
        <StyledTableCell>{request.height}m</StyledTableCell>
        <StyledTableCell>{request.ats?.name}</StyledTableCell>
        <StyledTableCell>{convertToLocal(request.endedDate)}</StyledTableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("height")}</StyledTableCell>
            <StyledTableCell>{t("ats")}</StyledTableCell>
            <StyledTableCell>{t("ended_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {earlierRequests?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ErasedTable = ({ erasedRequests, setSelectedRequest, handleShowRequestInfo, isLoading }) => {
  const { t } = useTranslation();

  const CustomRow = ({ request }) => {
    return (
      <TableRow hover key={request.id}>
        <StyledTableCell>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRequest(request);
              handleShowRequestInfo();
            }}
          >
            <InfoIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{request.id}</StyledTableCell>
        <StyledTableCell>{request.address}</StyledTableCell>
        <StyledTableCell>{request.height}m</StyledTableCell>
        <StyledTableCell>{request.ats?.name}</StyledTableCell>
        <StyledTableCell>{convertToLocal(request.plannedDate)}</StyledTableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer sx={{ marginBottom: 5 }} component={StyledPaper}>
      <LimitedBackdrop sx={{ backgroundColor: "#fffff", color: "#fffff", position: "absolute", zIndex: 1 }} open={isLoading}>
        <CircularProgress />
      </LimitedBackdrop>
      <Table size="small" className="table-text">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("id")}</StyledTableCell>
            <StyledTableCell>{t("address")}</StyledTableCell>
            <StyledTableCell>{t("height")}</StyledTableCell>
            <StyledTableCell>{t("ats")}</StyledTableCell>
            <StyledTableCell>{t("planned_date")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {erasedRequests?.map((request) => (
            <CustomRow key={request.id} request={request} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
