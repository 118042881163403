import { useTranslation } from "react-i18next";
import { convertToLocal, getTime } from "../../common/utils/functions";

export const Inside = () => {
  const { t } = useTranslation();

  return (
    <div
      className="inside"
      style={{
        display: "inline-block",
        backgroundColor: "#5cb85c",
        color: "#FFFFFF",
        fontWeight: "bold",
        borderRadius: 5,
        padding: 3,
        textAlign: "center",
        borderWidth: 0,
      }}
    >
      {t("inside")}
    </div>
  );
};

export const Unknown = () => {
  const { t } = useTranslation();

  return (
    <div
      className="unknown"
      style={{
        display: "inline-block",
        backgroundColor: "#f0ad4e",
        color: "#FFFFFF",
        fontWeight: "bold",
        borderRadius: 5,
        padding: 3,
        textAlign: "center",
        borderWidth: 10,
      }}
    >
      {t("unknown")}
    </div>
  );
};

export const Outdated = () => {
  const { t } = useTranslation();

  return (
    <div
      className="outdated"
      style={{
        display: "inline-block",
        backgroundColor: "#f0ad4e",
        color: "#FFFFFF",
        fontWeight: "bold",
        borderRadius: 5,
        padding: 3,
        textAlign: "center",
        borderWidth: 0,
      }}
    >
      {t("outdated")}
    </div>
  );
};

export const Outside = () => {
  const { t } = useTranslation();

  return (
    <div
      className="outside"
      style={{
        display: "inline-block",
        backgroundColor: "#d9534f",
        color: "#FFFFFF",
        fontWeight: "bold",
        borderRadius: 5,
        padding: 3,
        textAlign: "center",
        borderWidth: 0,
      }}
    >
      {t("outside")}
    </div>
  );
};
