import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { FormButton } from "../../../common/utils/stylings";
import { atsApi, countriesApi } from "../../../api/api";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

export const AtsPhoneNumberModal = ({ show, handleClose, selectedAts, handleOpenSnackbar, setAtses }) => {
  const [t] = useTranslation();

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required(t("phone_nr_required")),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onPatchAts(selectedAts.id, "/phoneNumber", "replace", fields.phoneNumber);
    },
  });

  useEffect(() => {
    if (selectedAts) {
      try {
        formik.setFieldValue("phoneNumber", selectedAts.phoneNumber);
      } catch (err) {
        console.log(err);
      }
    }
  }, [selectedAts]);

  const onPatchAts = (id, path, op, value) => {
    atsApi
      .patchAts(id, path, op, value)
      .then((res) => {
        atsApi
          .getAtses()
          .then((res) => {
            setAtses(res.data);
            handleOpenSnackbar("success", t("changes_saved"));
            handleClose();
            formik.resetForm();
          })
          .catch((err) => {
            handleOpenSnackbar("error", t("generic_error"));
            handleClose();
          });
      })
      .catch((err) => {
        handleOpenSnackbar("error", t("generic_error"));
        handleClose();
      });
  };

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <div style={{ marginBottom: 10 }}></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label={t("new_phone_nr")}
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button variant="contained" type="submit" color="primary" onClick={handleClose}>
            {t("save_changes")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
