import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../common/hooks/hooks";
import { accountApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { FormButton } from "../../common/utils/stylings";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const ChangeEmail = ({ handleOpenSnackbar }) => {
  const { setUser } = useContext(AccountContext);
  const { t } = useTranslation();
  let history = useHistory();

  const Status = {
    Verifying: "Verifying",
    Failed: "Failed",
    Success: "Success",
  };
  const [status, setStatus] = useState(Status.Verifying);
  const email = useQuery().get("email");
  const newEmail = useQuery().get("newEmail");
  const token = useQuery().get("token");

  const handleChangeEmailResend = () => {
    accountApi
      .changeEmailConfirmationLink(email, newEmail)
      .then((res) => {
        handleOpenSnackbar("success", res.data);
        history.push("/change-email-verification-sent");
      })
      .catch((err) => {
        if (err) {
          handleOpenSnackbar("error", err.response?.data);
        } else {
          handleOpenSnackbar("error", t("generic_error"));
        }
      });
  };

  useEffect(() => {
    accountApi
      .changeEmail(token, email, newEmail)
      .then(() => {
        setStatus(Status.Success);
      })
      .catch((err) => {
        if (!status === Status.Success) {
          setStatus(Status.Failed);
        }
        if (err) {
          console.log(err.response?.data);
        } else {
          console.log(t("generic_error"));
        }
      });
    return () => {
      // cleanup
      setStatus();
    };
  }, [Status.Failed, Status.Success, token, email, newEmail]);

  const getBody = () => {
    switch (status) {
      default:
        return <p>{t("verifying")}</p>;
      case Status.Failed:
        return (
          <div>
            <Alert severity="error">{t("email_not_updated")}</Alert>
            <FormButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleChangeEmailResend();
              }}
            >
              {t("send_again")}
            </FormButton>
          </div>
        );
      case Status.Success:
        return (
          <div>
            <Alert severity="success">{t("email_updated")}</Alert>
            <FormButton
              variant="contained"
              color="primary"
              onClick={() => {
                localStorage.clear();
                setUser(null);
                history.push("/login");
              }}
            >
              {t("login")}{" "}
            </FormButton>
          </div>
        );
    }
  };

  return (
    <div className="narrow-container">
      <div className="item">
        <div className="page-title">{t("email_verification")}</div>
        <div>{getBody()}</div>
      </div>
    </div>
  );
};

export default ChangeEmail;
