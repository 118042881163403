import React, { useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import { PreviewModal } from "../../../common/components/PreviewModal";
import { restrictedZonesApi } from "../../../api/api";
import { FormButton } from "../../../common/utils/stylings";
import { useTranslation } from "react-i18next";

const AreaForm = ({ ats, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name_required")),
    area: Yup.string()
      .required(t("area_required"))
      .matches(/^[0-9,\.\[\]]*$/, t("only_numbers_and_these_symbols"))
      .test("format", t("invalid_format"), (value) => {
        try {
          const parsed = JSON.parse(value);
          if (parsed.length === 0) return false;
          parsed.map((e) => {
            if (!Array.isArray(e)) {
              return false;
            }
          });
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      }),
  });

  const onPostRestrictedZone = (fields, ats, area) => {
    restrictedZonesApi
      .postRestrictedZone(fields, ats, area)
      .then((res) => {
        handleOpenSnackbar("success", t("restricted_area_created"));
        history.goBack();
      })
      .catch((err) => {
        handleOpenSnackbar("error", t("create_restricted_area_failed"));
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      area: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onPostRestrictedZone(fields, ats, JSON.parse(fields.area));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PreviewModal show={show} handleClose={handleClose} preview={preview} />
      <div>
        <TextField
          variant="standard"
          fullWidth
          id="name"
          name="name"
          label={t("name")}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          variant="standard"
          multiline
          rows={2}
          fullWidth
          id="area"
          area="area"
          label={t("area")}
          value={formik.values.area}
          onChange={formik.handleChange}
          error={formik.touched.area && Boolean(formik.errors.area)}
          helperText={formik.touched.area && formik.errors.area}
        />
        <Alert sx={{ marginBottom: 1, marginTop: 1, fontSize: 11 }} severity="info">
          {t("polygon_info")}: [[{t("lat")}, {t("lng")}],[{t("lat")}, {t("lng")}]]. {t("example")}:
          [[57.10767047261603,12.246970735156431],[57.257677016095315,12.195017752105384],[56.945690754327906,12.741481446690694]]{" "}
        </Alert>
        <Button
          type="button"
          sx={{ textTransform: "none" }}
          onClick={() => {
            try {
              const parseArea = JSON.parse(formik.values.area);
              setPreview(parseArea);
              handleShow();
            } catch (err) {
              handleOpenSnackbar("error", t("area_invalid"));
              console.log(t("area_invalid"), err);
            }
          }}
        >
          {t("preview_in_map")}{" "}
        </Button>
      </div>
      <FormButton type="submit" variant={"contained"}>
        {t("create_restricted_area")}
      </FormButton>
    </form>
  );
};

export default AreaForm;
