import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { FormButton } from "../../common/utils/stylings";
import { AccountContext } from "../../contexts/AccountContext";
import { accountApi } from "../../api/api";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import SmartDisplay from "@mui/icons-material/SmartDisplay";
import playstore from "../../assets/playstore.png";
import appstore from "../../assets/appstore.png";

const Login = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useContext(AccountContext);

  let history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("email_invalid")).required(t("email_required")),
    password: Yup.string().min(8, t("password_length")).required(t("password_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      setIsLoading(true);
      accountApi
        .login(fields)
        .then((u) => {
          if (u.data.roles.includes("Ats")) {
            setUser(u.data);
            localStorage.setItem("jwt", JSON.stringify(u.data));
            if (u.data.changePassword) history.push("/change-password");
            else history.push("/ats");
          } else {
            // not allowed to login
            handleOpenSnackbar("error", t("not_allowed"));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          try {
            if (err) {
              handleOpenSnackbar("error", err.response?.data);
            } else {
              handleOpenSnackbar("error", t("generic_error"));
            }
            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
          }
        });
    },
  });

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <div className="narrow-container">
        <Paper sx={{ padding: 2, marginTop: 1, backgroundColor: "#ffffffcc" }} elevation={3}>
          <div className="page-title">{t("login")}</div>
          <div style={{ marginTop: 10 }} />
          {/* <div style={{ fontSize: 18, fontWeight: 500 }}>{t("get_mobile_app")}</div>
          <div style={{ marginTop: 8 }} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a href="https://apps.apple.com/se/app/dronerequest/id6450983946">
              <img src={appstore} alt="" style={{ height: 40 }} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.naviation.dronerequest&pcampaignid=web_share">
              <img src={playstore} alt="" style={{ height: 40 }} />
            </a>
            <div style={{ marginTop: 5 }} />
          </div> */}
          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="standard"
              fullWidth
              id="email"
              name="email"
              label={t("email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              variant="standard"
              fullWidth
              id="password"
              name="password"
              label={t("password")}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormButton disabled={isLoading} variant="contained" color="primary" type="submit">
              {t("login")}
            </FormButton>
          </form>
          <div style={{ fontSize: 15 }}>
            <Link to="/forgot-password">{t("forgot_password")}</Link>
          </div>
          {/* <div style={{ marginTop: 10, marginBottom: 10 }}></div>
          <div style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            <a href="https://www.youtube.com/watch?v=sykyZjTBcDM">
              <SmartDisplay fontSize="large" />
            </a>
            <a href="https://www.youtube.com/watch?v=sykyZjTBcDM">{t("instructional_video")}</a>
          </div> */}
        </Paper>
      </div>
    </div>
  );
};

export default Login;
