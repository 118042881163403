import React, { useState, useEffect, useContext } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { BackButton } from "../../common/components/BackButton";
import { useQuery } from "../../common/hooks/hooks";
import { atsApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import AreaForm from "./components/AreaForm";
import PointWithRadiusForm from "./components/PointWithRadiusForm";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";

const CreateRestrictedZone = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const id = useQuery().get("id");
  const [ats, setAts] = useState();
  const [tab, setTab] = useState(0);

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    const retrieveCreateRestrictedZoneData = () => {
      setIsPageLoading(true);
      atsApi
        .getAts(id)
        .then((res) => {
          setAts(res.data);
          setIsPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsPageLoading(false);
        });
    };
    retrieveCreateRestrictedZoneData();
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  if (isPageLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="narrow-container">
        <Paper sx={{ padding: 2 }} elevation={3}>
          <div className="item">
            <BackButtonWithTitle title={t("create_new_restricted_area")} />
            <Tabs
              value={tab}
              onChange={(e, newValue) => {
                setTab(newValue);
              }}
              aria-label="tabs"
            >
              <Tab label={t("area_tab")} />
              <Tab label={t("radius_tab")} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <AreaForm ats={ats} handleOpenSnackbar={handleOpenSnackbar} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <PointWithRadiusForm ats={ats} handleOpenSnackbar={handleOpenSnackbar} />
            </TabPanel>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CreateRestrictedZone;
