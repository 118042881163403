import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../common/components/BackButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import Box from "@mui/material/Box";
import { PreviewModal } from "../../common/components/PreviewModal";
import { FormButton } from "../../common/utils/stylings";
import { countryCodes } from "../../common/utils/countryCodes";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { atsApi, countriesApi } from "../../api/api";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import Checkbox from "@mui/material/Checkbox";

const CreateAts = ({ handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);
  const [countries, setCountries] = useState([]);

  const [preview, setPreview] = useState();
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zåäöA-ZÅÄÖ\s\(\)]+$/, t("only_letters_and_whitespace"))
      .required(t("name_required")),
    phoneNumber: Yup.string().required(t("phone_nr_required")),
    latitude: Yup.string()
      .matches(/^[1-9]\d*(.\d+)?$/, t("only_numbers_and_decimals"))
      .required(t("latitude_required")),
    longitude: Yup.string()
      .matches(/^[1-9]\d*(.\d+)?$/, t("only_numbers_and_decimals"))
      .required(t("longitude_required")),
    email: Yup.string().email(t("email_invalid")).required(t("email_required")),
    accountName: Yup.string()
      .matches(/^[a-zåäöA-ZÅÄÖ\s]+$/, t("only_letters_and_whitespace"))
      .required(t("name_required")),
    password: Yup.string().min(8, t("password_length")).required(t("password_required")),
    confirmPassword: Yup.string().required(t("repeat_password_required")),
    airspace: Yup.string()
      .required(t("airspace_required"))
      .matches(/^[0-9,\.\[\]]*$/, t("only_numbers_and_these_symbols"))
      .test("format", t("invalid_format"), (value) => {
        try {
          const parsed = JSON.parse(value);
          if (parsed.length === 0) return false;
          parsed.map((e) => {
            if (!Array.isArray(e)) {
              return false;
            }
          });
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      country: "",
      latitude: "",
      longitude: "",
      airspace: "",
      disclaimer: "",
      email: "",
      accountName: "",
      password: "",
      confirmPassword: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      console.log(fields);
      if (fields.password !== fields.confirmPassword) {
        handleOpenSnackbar("error", "password_match");
        return;
      }
      onPostAts(fields, JSON.parse(fields.airspace), emailNotifications);
    },
  });

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    countriesApi
      .getCountries()
      .then((res) => {
        setCountries(res.data);
        let c = "";
        try {
          const cookie = document.cookie.split("; ");
          const language = cookie.filter((item) => {
            return item.includes("LANG");
          });
          if (language.length > 0) {
            const cookieLang = language[0].split("=")[1];
            const get639 = cookieLang.split("-")[0];
            c = res.data.find((c) => c.iso639 === get639);
          } else {
            const browserLang = navigator.language || navigator.userLanguage;
            c = res.data.find((c) => c.iso639 === browserLang);
          }
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        } catch (err) {
          c = res.data.find((c) => c.iso639 === "en");
          formik.setFieldValue("country", c.iso3166);
          formik.setFieldValue("phoneNumber", c.phonePrefix);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onPostAts = (fields, airspace, emailNotifications) => {
    setIsLoading(true);
    atsApi
      .postAts(fields, airspace, emailNotifications)
      .then((res) => {
        setIsLoading(false);
        handleOpenSnackbar("success", res.data);
        history.goBack();
      })
      .catch((err) => {
        try {
          if (err) {
            handleOpenSnackbar("error", err.response?.data);
          } else {
            handleOpenSnackbar("error", t("generic_error"));
          }
        } catch (err) {
          handleOpenSnackbar("error", t("generic_error"));
        }
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    formik.setFieldValue("country", event.target.value);
    if (!event.target.value) formik.setFieldValue("phoneNumber", "");
    else {
      const c = countries.find((c) => c.iso3166 === event.target.value);
      formik.setFieldValue("phoneNumber", c.phonePrefix);
    }
  };

  return (
    <div>
      <PreviewModal show={show} handleClose={handleClose} preview={preview} />
      <div className="narrow-container">
        <Paper sx={window.innerWidth > 1000 ? { padding: 2 } : { padding: 0 }} elevation={3}>
          <BackButtonWithTitle title={t("create_new_ats")} />
          <div className="item">
            <form onSubmit={formik.handleSubmit}>
              <Paper sx={{ padding: 1, marginBottom: 2 }} elevation={1}>
                <h2>{t("ats")}</h2>
                <TextField
                  variant="standard"
                  fullWidth
                  id="name"
                  name="name"
                  label={t("name")}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <FormControl sx={{ width: "70%", marginRight: 0 }} variant="standard">
                    <InputLabel shrink={true} id="ats-select">
                      {t("country_code")}
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.country}
                      onChange={handleChange}
                      inputProps={{
                        name: "ats",
                        id: "uncontrolled-native",
                      }}
                      id="ats-select"
                    >
                      {countries?.map((country) => {
                        return (
                          <option key={country.iso3166} value={country.iso3166}>
                            {t(`${country.iso3166}`)}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>
                  <TextField
                    variant="standard"
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label={t("phone_nr")}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <TextField
                    style={{ width: "49%" }}
                    variant="standard"
                    fullWidth
                    name="latitude"
                    type="number"
                    label={t("latitude")}
                    value={formik.values.latitude}
                    onChange={formik.handleChange}
                    error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                    helperText={formik.touched.latitude && formik.errors.latitude}
                  />
                  <TextField
                    style={{ width: "49%" }}
                    variant="standard"
                    fullWidth
                    name="longitude"
                    type="number"
                    label={t("longitude")}
                    value={formik.values.longitude}
                    onChange={formik.handleChange}
                    error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                    helperText={formik.touched.longitude && formik.errors.longitude}
                  />
                </div>
                <TextField
                  variant="standard"
                  multiline
                  rows={2}
                  fullWidth
                  id="airspace"
                  airspace="airspace"
                  label={t("airspace")}
                  value={formik.values.airspace}
                  onChange={formik.handleChange}
                  error={formik.touched.airspace && Boolean(formik.errors.airspace)}
                  helperText={formik.touched.airspace && formik.errors.airspace}
                />
                <Alert sx={{ marginBottom: 1, marginTop: 1 }} severity="info">
                  {t("polygon_info")}: [[{t("lat")}, {t("lng")}],[{t("lat")}, {t("lng")}]]. {t("example")}:
                  [[57.10767047261603,12.246970735156431],[57.257677016095315,12.195017752105384],[56.945690754327906,12.741481446690694]]{" "}
                </Alert>
                <Button
                  type="button"
                  disabled={isLoading}
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    try {
                      const parseArea = JSON.parse(formik.values.airspace);
                      setPreview(parseArea);
                      handleShow();
                    } catch (err) {
                      handleOpenSnackbar("error", t("area_invalid"));
                      console.log(t("area_invalid"), err);
                    }
                  }}
                >
                  {t("preview_in_map")}
                </Button>
                <TextField
                  variant="standard"
                  fullWidth
                  id="disclaimer"
                  name="disclaimer"
                  label={t("disclaimer")}
                  value={formik.values.disclaimer}
                  onChange={formik.handleChange}
                  error={formik.touched.disclaimer && Boolean(formik.errors.disclaimer)}
                  helperText={formik.touched.disclaimer && formik.errors.disclaimer}
                />
                <div style={{ display: "flex", marginTop: 10 }}>
                  <Checkbox
                    value={emailNotifications}
                    checked={emailNotifications}
                    onChange={() => {
                      setEmailNotifications(!emailNotifications);
                    }}
                  />
                  <div>Jag vill få e-postavisering vid skapande av ny förfrågan.</div>
                </div>
              </Paper>
              <Paper sx={{ padding: 2 }} elevation={1}>
                <h2> {t("login_info")}</h2>
                <TextField
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  label={t("email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  id="accountName"
                  name="accountName"
                  label={t("account_name")}
                  value={formik.values.accountName}
                  onChange={formik.handleChange}
                  error={formik.touched.accountName && Boolean(formik.errors.accountName)}
                  helperText={formik.touched.accountName && formik.errors.accountName}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  id="password"
                  name="password"
                  label={t("password")}
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  variant="standard"
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t("repeat_password")}
                  type="password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
                <Alert sx={{ marginTop: 1 }} severity="warning">
                  {t("remember_password_change")}
                </Alert>
              </Paper>

              <FormButton type="submit" variant={"contained"}>
                {t("create_ats")}
              </FormButton>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CreateAts;
