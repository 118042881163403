import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AccountContext } from "../../contexts/AccountContext";
import { accountApi } from "../../api/api";

const Main = () => {
  const { user } = useContext(AccountContext);

  let history = useHistory();

  useEffect(() => {
    accountApi.checkToken().then((res) => {
      if (res.status === 401) {
        history.push("/login");
      }
    });

    if (!user) history.push("/login");
    else if (user.changePassword) history.push("/change-password");
    else if (user.roles.includes("Admin")) history.push("/admin");
    else if (user.roles.includes("Ats")) history.push("/ats");
    else history.push("/pilot");
  }, []);

  return <></>;
};

export default Main;
