import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { atsApi, accountApi } from "../../api/api";
import { AccountContext } from "../../contexts/AccountContext";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";

const Profile = ({ lng, setLng }) => {
  const { user, setUser } = useContext(AccountContext);
  const { t, i18n } = useTranslation();
  const [atses, setAtses] = useState();
  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 50);
  }, []);

  useEffect(() => {
    atsApi
      .getAtsesProduction()
      .then((res) => {
        setAtses(res.data);
      })
      .catch((err) => console.log(err));
  }, [user]);

  const handleChangeLang = (event) => {
    setLng(event.target.value);
    i18n.changeLanguage(event.target.value);
    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
    document.cookie = `LANG=${event.target.value}; expires=${expires}`;
  };

  const handleChangeAts = (event) => {
    const selected = atses.find((ats) => ats.name === event.target.value);
    if (selected) {
      accountApi
        .addUserToATS(user, selected.id)
        .then((res) => {
          try {
            const updatedUser = { ...user, ats: selected };
            localStorage.clear();
            localStorage.setItem("jwt", JSON.stringify(updatedUser));
            setUser(updatedUser);
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="narrow-container">
      <Paper sx={{ padding: 2 }} elevation={3}>
        <BackButtonWithTitle title={t("account")} />
        <div className="item">
          <div style={{ fontSize: 15 }}>
            {t("email")}:{user && <b> {user.email}</b>}
          </div>
          <div style={{ fontSize: 15 }}>
            {t("name")}:{user && <b> {user.displayName}</b>}
          </div>
          {user.roles.includes("Ats") && (
            <div style={{ fontSize: 15 }}>
              {t("ats")}:<b> {user.atsName}</b>
            </div>
          )}
          <div>
            <div style={{ marginTop: 10, fontSize: 15 }}>
              <Link to="/change-email-request">{t("change_email")}</Link>
            </div>
            {!user.roles.includes("Ats") && (
              <div style={{ fontSize: 15 }}>
                <Link to="/change-phone-number">{t("change_phone")}</Link>
              </div>
            )}
            <div style={{ fontSize: 15 }}>
              <Link to="/change-password">{t("change_password")}</Link>
            </div>
          </div>

          {user.roles.includes("Pilot") && (
            <div style={{ marginTop: "1rem" }}>
              <FormControl variant="standard" sx={{ width: 190 }}>
                <InputLabel shrink={true} id="ats-select">
                  {t("preselected_ats")}
                </InputLabel>
                {user.atsName ? (
                  <NativeSelect
                    value={user.atsName ? user.atsName : ""}
                    size="small"
                    id="ats-select"
                    onChange={handleChangeAts}
                    inputProps={{
                      name: "ats",
                      id: "uncontrolled-native",
                    }}
                  >
                    {atses?.map((ats) => {
                      return (
                        <option key={ats.id} value={ats.name}>
                          {ats.name}
                        </option>
                      );
                    })}
                  </NativeSelect>
                ) : (
                  <NativeSelect
                    id="ats-select"
                    value=""
                    size="small"
                    onChange={handleChangeAts}
                    inputProps={{
                      name: "ats",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option key={"null"} value=""></option>
                    {atses?.map((ats) => {
                      return (
                        <option key={ats.id} value={ats.name}>
                          {ats.name}
                        </option>
                      );
                    })}
                  </NativeSelect>
                )}
              </FormControl>
            </div>
          )}
          <div>
            <FormControl variant="standard" sx={{ width: 190, marginTop: 2 }}>
              <InputLabel shrink={true} id="language-select">
                {t("language")}
              </InputLabel>
              <NativeSelect
                size="small"
                value={lng}
                inputProps={{
                  name: "language",
                  id: "uncontrolled-native",
                }}
                id="lang-select"
                onChange={handleChangeLang}
              >
                <option value={"sv-SE"}>{t("swedish")}</option>
                <option value={"en-GB"}>{t("english")}</option>
              </NativeSelect>
            </FormControl>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Profile;
