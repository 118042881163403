import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { AccountContextProvider } from "./contexts/AccountContext";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n/config";
import "./index.css";

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <AccountContextProvider>
      <App />
    </AccountContextProvider>
  </Router>,

  // </React.StrictMode>
  document.getElementById("root")
);
