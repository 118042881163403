import React, { useContext } from "react";
import { AccountContext } from "..//contexts/AccountContext";
import { LoggedIn } from "./LoggedIn";
import { LoggedOut } from "./LoggedOut";

export const Navigation = ({ handleOpenSnackbar, lng, setLng }) => {
  const { user } = useContext(AccountContext);

  return (
    <>
      {user ? (
        <LoggedIn handleOpenSnackbar={handleOpenSnackbar} lng={lng} setLng={setLng} />
      ) : (
        <LoggedOut handleOpenSnackbar={handleOpenSnackbar} />
      )}
    </>
  );
};
