import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { commentsApi } from "../../../api/api";
import { convertToLocal } from "../../../common/utils/functions";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import { DialogTitle, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { requestsApi } from "../../../api/api";

export const CommentModal = ({
  show,
  handleClose,
  selectedRequest,
  user,
  isLoading,
  setActive,
  setUpcoming,
  setEarlier,
  setErased,
  setTotalPagesUpcoming,
  setIsLoading,
  setTotalPagesEarlier,
  setTotalPagesErased,
  pageSize,
}) => {
  const { t } = useTranslation();
  const [modalToggle, setModalToggle] = useState(true);
  const [comments, setComments] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);

  useEffect(() => {
    if (selectedRequest && selectedRequest.email) {
      setIsLoadingComments(true);
      commentsApi.getCommentsByUser(selectedRequest.email).then((res) => {
        setComments(res.data);
        setIsLoadingComments(false);
      });
    }
    setModalToggle(true);
    return () => {
      setComments([]);
    };
  }, [selectedRequest]);

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: (fields) => {
      if (fields.comment) {
        setIsLoading(true);
        setModalToggle(true);
        commentsApi
          .postComment(user, fields.comment, selectedRequest.email)
          .then((res) => {
            Promise.all([
              requestsApi.getActiveByAts(user.atsIcaoLocationIndicator),
              requestsApi.getUpcomingByAts(1, pageSize, user.atsIcaoLocationIndicator),
              requestsApi.getEarlierByAts(1, pageSize, user.atsIcaoLocationIndicator),
              requestsApi.getErasedByAts(1, pageSize, user.atsIcaoLocationIndicator),
            ])
              .then(([act, upc, ear, era]) => {
                const paginationUpcoming = JSON.parse(upc.headers.pagination);
                const paginationEarlier = JSON.parse(ear.headers.pagination);
                const paginationErased = JSON.parse(era.headers.pagination);

                setTotalPagesUpcoming(paginationUpcoming.TotalPages);
                setTotalPagesEarlier(paginationEarlier.TotalPages);
                setTotalPagesErased(paginationErased.TotalPages);

                setActive(act.data);
                setUpcoming(upc.data);
                setEarlier(ear.data);
                setErased(era.data);

                formik.resetForm();
                setIsLoading(false);
                handleClose();
              })
              .catch((err) => {
                setIsLoading(false);
              });
            setModalToggle(true);
          })
          .catch((err) => {
            formik.resetForm();
          });
      }
    },
  });

  if (!selectedRequest) return null;

  if (isLoadingComments) return null;

  return (
    <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t("comments")}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {modalToggle ? (
            <>
              {comments?.map((comment) => {
                return (
                  <ul key={comment.id} className="comment-list">
                    <li className="comment-item">
                      <b>
                        {comment.author} - {convertToLocal(comment.date)}
                      </b>
                      <div className="mb-2">{comment.body}</div>
                    </li>
                  </ul>
                );
              })}
            </>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField name="comment" id="comment" placeholder={t("write_comment")} variant="outlined" size="small" value={formik.values.comment} onChange={formik.handleChange} />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {modalToggle ? (
            <>
              <Button variant="outlined" disabled={isLoading} onClick={handleClose}>
                {t("close")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  setModalToggle(false);
                }}
              >
                {t("add_comment")}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="error"
                disabled={isLoading}
                onClick={() => {
                  setModalToggle(true);
                }}
              >
                {t("cancel")}
              </Button>
              <Button variant="contained" type="submit" color="primary" disabled={isLoading}>
                {t("save_changes")}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
