import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { standardRequestsApi } from "../../api/api";
import { BackButton } from "../../common/components/BackButton";
import { PreviewModal } from "../../common/components/PreviewModal";
import { AccountContext } from "../../contexts/AccountContext";
import { scrollToTop } from "../../common/utils/functions";
import Button from "@mui/material/Button";
import { FormButton } from "../../common/utils/stylings";
import { useTranslation } from "react-i18next";
import { BackButtonWithTitle } from "../../common/components/BackButtonWithTitle";
import Paper from "@mui/material/Paper";

const EditStandardRequest = ({ selected, handleOpenSnackbar }) => {
  const { t } = useTranslation();
  const { user } = useContext(AccountContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [preview, setPreview] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zåäöA-ZÅÄÖ\s\(\)]+$/, t("only_letters_and_whitespace"))
      .required(t("name_required")),
    area: Yup.string()
      .required(t("area_required"))
      .matches(/^[0-9,\.\[\]]*$/, t("only_numbers_and_these_symbols"))
      .test("format", t("invalid_format"), (value) => {
        try {
          const parsed = JSON.parse(value);
          if (parsed.length === 0) return false;
          parsed.map((e) => {
            if (!Array.isArray(e)) {
              return false;
            }
          });
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      area: "",
      error: null,
    },
    validationSchema: validationSchema,
    onSubmit: (fields) => {
      onPutStandardRequest(fields, selected, JSON.parse(fields.area));
    },
  });

  let history = useHistory();

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  useEffect(() => {
    try {
      formik.setFieldValue("name", selected.name);
      formik.setFieldValue("area", JSON.stringify(selected.area));
    } catch (err) {
      setError(err);
    }
  }, []);

  const onPutStandardRequest = (fields, selected, area) => {
    setIsLoading(true);
    standardRequestsApi
      .putStandardRequest(fields, selected, area)
      .then((res) => {
        handleOpenSnackbar("success", t("map_layer_updated"));
        setIsLoading(false);
        history.goBack();
      })
      .catch((err) => {
        handleOpenSnackbar("error", t("generic_error"));
        setIsLoading(false);
        setError(err);
      });
  };

  useEffect(() => {
    if (!user) history.push("/");
    else if (user.changePassword) history.push("/change-password");
    else if (!user.roles.includes("Admin")) history.push("/unauthorized");
    scrollToTop();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <PreviewModal show={show} handleClose={handleClose} preview={preview} />
      <div className="narrow-container">
        <Paper sx={{ padding: 2 }} elevation={3}>
          <BackButtonWithTitle title={t("edit_map_layer")} />
          <div className="item">
            <form onSubmit={formik.handleSubmit}>
              <PreviewModal show={show} handleClose={handleClose} preview={preview} />
              <div>
                <TextField
                  variant="standard"
                  fullWidth
                  id="name"
                  name="name"
                  label="Namn"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  variant="standard"
                  multiline
                  rows={2}
                  fullWidth
                  id="area"
                  area="area"
                  label="Område"
                  value={formik.values.area}
                  onChange={formik.handleChange}
                  error={formik.touched.area && Boolean(formik.errors.area)}
                  helperText={formik.touched.area && formik.errors.area}
                />
                <Alert sx={{ marginBottom: 1, marginTop: 1, fontSize: 11 }} severity="info">
                  {t("polygon_info")}: [[{t("lat")}, {t("lng")}],[{t("lat")}, {t("lng")}]]. {t("example")}:
                  [[57.10767047261603,12.246970735156431],[57.257677016095315,12.195017752105384],[56.945690754327906,12.741481446690694]]{" "}
                </Alert>
                <Button
                  type="button"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    try {
                      const parseArea = JSON.parse(formik.values.area);
                      setPreview(parseArea);
                      handleShow();
                    } catch (err) {
                      handleOpenSnackbar("error", "Ogiltigt format på område");
                      console.log("Område är inte korrekt ifyllt", err);
                    }
                  }}
                >
                  {t("preview_in_map")}
                </Button>
              </div>
              <FormButton type="submit" variant={"contained"}>
                {t("save_changes")}
              </FormButton>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default EditStandardRequest;
